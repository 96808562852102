import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Table,
  TableSortLabel,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import { AiOutlineSearch } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { AiOutlineEye } from "react-icons/ai";
import CreateShipment from "./Action/CreateDevice";
import CustomTooltip from "../../component/CustomTooltip/CustomTooltip";
import { axiosInstance } from "../../component/axios/axiosInstance";
import DeleteDevice from "./Action/DeleteDevice";

export default function Home() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState(null);
  const handleSearchClose = () => {
    setSearchTerm("");
  };
  const [respDevice, setDevice] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleRequestSort = (event, key) => {
    const isAsc = orderBy === key && order === "asc";
    const newOrder = orderBy === key ? (isAsc ? "desc" : "asc") : "desc";
    setOrder(newOrder);
    setOrderBy(key);
  };
  const SortValue = order === "asc" ? 1 : -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const apiUrl = "/api/device/fetchDevices";

  const params = {
    search: searchTerm,
    filter: { status: { $ne: -1 } },
    page: page + 1,
    limit: rowsPerPage,
    select: {},
    startDate: "",
    endDate: "",
    sort: orderBy == "" ? { createdAt: -1 } : { [orderBy]: SortValue },
  };

  async function fetchData() {
    try {
      const response = await axiosInstance.get(apiUrl, { params });
      setDevice(response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  useEffect(() => {
    if (searchTerm?.length > 0) {
      fetchData();
    } else if (orderBy && order) {
      fetchData();
    } else {
      fetchData();
    }
  }, [searchTerm, page, rowsPerPage, SortValue]);
  return (
    <>
      <Grid container justifyContent="space-between" mt={13}>
        <Typography variant="h6">Manage Device Management</Typography>

        <CreateShipment fetchData={fetchData} />
      </Grid>
      <TextField
        size="small"
        className="search-input mt-20px"
        placeholder="Search  UID or Name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{
          "& .MuiOutlinedInput-root": { borderRadius: 4 },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AiOutlineSearch fontSize="large" />
            </InputAdornment>
          ),
          endAdornment: searchTerm?.length > 0 && (
            <InputAdornment position="end">
              <IoClose
                fontSize="large"
                className="cursor-point"
                onClick={handleSearchClose}
              />
            </InputAdornment>
          ),
        }}
      />
      <Typography variant="subtitle2" pt={2}>
        Showing {respDevice?.devices?.result?.length} of{" "}
        {respDevice?.devices?.totalCount} Device
      </Typography>
      <TableContainer className="mt-20px ">
        <Table
          stickyHeader
          aria-label="sticky table"
          className="mt-20"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell className="tablecell-bg">
                <TableSortLabel
                  active={orderBy === "deviceUid"}
                  direction={orderBy === "deviceUid" ? order : "asc"}
                  onClick={(event) => handleRequestSort(event, "deviceUid")}
                >
                  Device UID
                </TableSortLabel>
              </TableCell>
              <TableCell className="tablecell-bg">
                <TableSortLabel
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? order : "asc"}
                  onClick={(event) => handleRequestSort(event, "name")}
                >
                  Device Name
                </TableSortLabel>
              </TableCell>
              <TableCell className="tablecell-bg">MAC ID</TableCell>

              <TableCell className="tablecell-bg">
                <TableSortLabel
                  active={orderBy === "status"}
                  direction={orderBy === "status" ? order : "asc"}
                  onClick={(event) => handleRequestSort(event, "status")}
                >
                  Status{" "}
                </TableSortLabel>
              </TableCell>
              <TableCell className="tablecell-bg">
                <TableSortLabel
                  active={orderBy === "createdAt"}
                  direction={orderBy === "createdAt" ? order : "asc"}
                  onClick={(event) => handleRequestSort(event, "createdAt")}
                >
                  Added on
                </TableSortLabel>
              </TableCell>

              <TableCell className="tablecell-bg">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {respDevice?.devices?.result?.map((item, index) => {
              return (
                <TableRow key={index} hover className="clickable-row">
                  <TableCell className="tablecrow-cell-bg ">
                    {item?.deviceUid}
                  </TableCell>

                  <TableCell className="tablecrow-cell-bg ">
                    {item?.name}
                  </TableCell>
                  <TableCell className="tablecrow-cell-bg ">
                    {item?.macId}
                  </TableCell>

                  <TableCell className="">
                    {item?.status === 0 ? (
                      <Typography className="status-n-a">
                        Not Assigned
                      </Typography>
                    ) : (
                      <Typography className="status-ongoing">
                        Assigned
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell className="tablecrow-cell-bg ">
                    {item?.updatedAt
                      ? dayjs(item?.updatedAt).format("h:mm A")
                      : dayjs(item?.createdAt).format("h:mm A")}

                    <br />
                    <span className="tablecell-bg">
                      {item?.updatedAt
                        ? dayjs(item?.updatedAt).format("DD-MM-YYYY")
                        : dayjs(item?.createdAt).format("DD-MM-YYYY")}
                    </span>
                  </TableCell>
                  <TableCell className="tablecrow-cell-bg ">
                    <Grid container justifyContent={"space-between"}>
                      <Grid item>
                        <CustomTooltip title={"View Profile"}>
                          <IconButton
                            onClick={() =>
                              navigate(`/device-details/${item._id}`)
                            }
                          >
                            <AiOutlineEye />
                          </IconButton>
                        </CustomTooltip>
                      </Grid>

                      <Grid item>
                        {item?.status == 0 && (
                          <DeleteDevice item={item} fetchData={fetchData} />
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {respDevice?.devices?.result?.length < 1 && (
        <Grid
          container
          justifyContent={"center"}
          alignContent={"center"}
          sx={{ height: "40vh" }}
        >
          <Typography>No Data Found</Typography>
        </Grid>
      )}
      <div style={{ marginBottom: "50px" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={
            respDevice?.devices?.totalCount
              ? respDevice?.devices?.totalCount
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
