import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import {
  Box,
  Grid,
  Button,
  Table,
  TableSortLabel,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import { axiosInstance } from "../../../component/axios/axiosInstance";
import { IoClose } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";
import CircularProgress from "../../../component/CustomLoader";
export default function Home() {
  const { id } = useParams();
  const tableContainerRef = useRef();

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchClose = () => {
    setSearchTerm("");
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [alertDetails, setAlertDetails] = useState("");
  const [loading, setLoading] = useState(true);

  const data = alertDetails?.alerts?.result;
  const handleRequestSort = (event, key) => {
    const isAsc = orderBy === key && order === "asc";
    const newOrder = orderBy === key ? (isAsc ? "desc" : "asc") : "desc";
    setOrder(newOrder);
    setOrderBy(key);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };
  const SortValue = order === "asc" ? 1 : -1;

  const apiUrl = `/api/alert/fetchAlerts`;

  const params = {
    search: searchTerm,
    filter: { order_ref: id },
    page: page + 1,
    limit: rowsPerPage,
    select: {},
    startDate: "",
    endDate: "",
    sort: orderBy == "" ? { createdAt: -1 } : { [orderBy]: SortValue },
  };

  async function GetAlertDetails() {
    try {
      setLoading(true);

      const response = await axiosInstance.get(apiUrl, { params });
      setAlertDetails(response.data);
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (searchTerm?.length > 0 && id) {
      GetAlertDetails();
    } else if (orderBy && order && id) {
      GetAlertDetails();
    } else {
      GetAlertDetails();
    }
  }, [searchTerm, page, rowsPerPage, SortValue, id]);

  const handleExport = () => {
    const modifiedData = data?.map((row) => {
      const unitMappings = {
        "Battery Sensor": "%",
        "Orientation Sensor": "°",
        "G-Force Sensor": "G",
        "Temperature Sensor": "°C",
        "Humidity Sensor": "%",
      };
      const unit = unitMappings[row?.sensor] || "";
      const Time = dayjs(row?.createdAt).format("h:mm A");
      const Date = dayjs(row?.createdAt).format("DD-MM-YYYY");
      const AlertName = row?.name;
      const SensorName = row?.sensor;
      const ThresholdValue = `${row?.threshold} ${
        row?.threshold === 0 ? "" : row?.threshold && unit
      }`;
      const AlertValue = `${row?.alertValue} ${
        row?.alertValue === 0 ? "" : row?.alertValue && unit
      }`;

      return {
        Time,
        Date,
        AlertName,
        SensorName,
        ThresholdValue,
        AlertValue,
      };
    });
    const csvData = [];
    const tableHeading = "Manage Alerts";
    csvData.push([[], [], tableHeading, [], []]);
    csvData.push([]);
    const headerRow = [
      "Time",
      "Date",
      "Alert Name",
      "Sensor Name",
      "Threshold Value",
      "Alert Value",
    ];
    csvData.push(headerRow);
    modifiedData.forEach((row) => {
      const rowData = [
        row.Time,
        row.Date,
        row.AlertName,
        row.SensorName,
        row.ThresholdValue,
        row.AlertValue,
      ];
      csvData.push(rowData);
    });

    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Alert Report.csv");
  };
  useEffect(() => {
    if (tableContainerRef && tableContainerRef.current) {
      const tableContainer = tableContainerRef.current;
      // Store the current scroll position
      const scrollPosition = tableContainer.scrollTop;

      // After loading data, restore the scroll position
      tableContainer.scrollTop = scrollPosition;
    }
  }, [page, rowsPerPage, tableContainerRef]); // Add other dependencies if needed

  return (
    <>
      <Typography variant="h6">Manage Alerts</Typography>
      <Grid container mt={1} justifyContent="space-between">
        <Grid item>
          <TextField
            size="small"
            className="search-input mt-20px"
            placeholder="Search  Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": { borderRadius: 4 },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AiOutlineSearch fontSize="large" />
                </InputAdornment>
              ),
              endAdornment: searchTerm?.length > 0 && (
                <InputAdornment position="end">
                  <IoClose
                    fontSize="large"
                    className="cursor-point"
                    onClick={handleSearchClose}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="subtitle2" pt={2}>
            Showing {alertDetails?.alerts?.result?.length} of{" "}
            {alertDetails?.alerts?.totalCount} Alert
          </Typography>
        </Grid>

        <Grid item mt={3}>
          <Button
            variant="contained"
            size="medium"
            onClick={handleExport}
            style={{
              color: "#fff",
              backgroundColor: "#5932EA",
              border: `1px solid ${"#ddd"}`,
              "&:hover": {
                backgroundColor: "#fff",
                color: "white",
              },
            }}
            startIcon={<FiDownload />}
          >
            Download Report
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "400px" }}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          <TableContainer
            ref={tableContainerRef}
            className="mt-20px table-container"
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              className="mt-20"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className="tablecell-bg"
                    sx={{ padding: "14px 16px" }}
                  >
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={orderBy === "name" ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, "name")}
                    >
                      Alert Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="tablecell-bg">
                    <TableSortLabel
                      active={orderBy === "alertValue"}
                      direction={orderBy === "alertValue" ? order : "asc"}
                      onClick={(event) =>
                        handleRequestSort(event, "alertValue")
                      }
                    >
                      Alert value
                    </TableSortLabel>
                  </TableCell>{" "}
                  <TableCell className="tablecell-bg">
                    <TableSortLabel
                      active={orderBy === "threshold"}
                      direction={orderBy === "threshold" ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, "threshold")}
                    >
                      Threshold Value
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="tablecell-bg">
                    <TableSortLabel
                      active={orderBy === "createdAt"}
                      direction={orderBy === "createdAt" ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, "createdAt")}
                    >
                      Time And date
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alertDetails?.alerts?.result?.map((item, index) => {
                  const unitMappings = {
                    "Battery Status": "%",
                    "G-Force Sensor": "G",
                    "Temperature Sensor": "°C",
                    "Humidity Sensor": "%",
                  };
                  const unit = unitMappings[item?.sensor] || "";
                  return (
                    <TableRow key={index} hover className="clickable-row">
                      <TableCell className="tablecrow-cell-bg ">
                        {item?.name}
                        <br />
                        <span className="tablecell-bg"> {item?.sensor}</span>
                      </TableCell>
                      <TableCell
                        className={
                          item?.alertValue === "Weak Signal"
                            ? "tablecrow-cell-bg-orange"
                            : "tablecrow-cell-bg-red"
                        }
                      >
                        {item?.alertValue}
                        {item?.alertValue == 0 ? "" : item?.alertValue && unit}
                      </TableCell>{" "}
                      <TableCell className="tablecrow-cell-bg ">
                        {item?.threshold ?? "--"}
                      </TableCell>
                      <TableCell className="tablecrow-cell-bg ">
                        {dayjs(item?.createdAt).format("h:mm A")},
                        <br />
                        <span className="tablecell-bg">
                          {dayjs(item?.createdAt).format("DD-MM-YYYY")}
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {alertDetails?.alerts?.result?.length < 1 && (
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              sx={{ height: "40vh" }}
            >
              <Typography>No Data Found</Typography>
            </Grid>
          )}
          <TablePagination
            rowsPerPageOptions={[
              10,
              25,
              50,
              100,
              200,
              { label: "All", value: 10000 },
            ]}
            component="div"
            count={alertDetails?.alerts?.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
}
