import React, { useRef, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import {
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  IconButton,
  DialogContent,
  Dialog,
  Grid,
  Box,
} from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
  DirectionsService,
  InfoWindow,
} from "@react-google-maps/api";
import CloseIcon from "@mui/icons-material/Close";
import CustomTooltip from "../../../component/CustomTooltip/CustomTooltip";
import { axiosInstance } from "../../../component/axios/axiosInstance";
import { MAP_KEY } from "../../../component/Key/constant";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        color: "white",
        backgroundColor: "#5932EA",
        m: 0,
        p: "10px 15px 10px 10px",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          className="dialog-iconbtn"
          sx={{
            color: (theme) => theme.palette.common.white,
            backgroundColor: "transparent",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
export default function DownloadReport({ SpeedReport }) {
  const { id } = useParams();
  const mapRef = useRef(null);
  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("lg");
  const [loading, setLoading] = useState(false); // Add loading state
  const [directions, setDirections] = useState();
  const [originCoord, setOriginCoord] = useState(null);
  const [originAddress, setOriginAddress] = useState(null);
  const [destinationAddress, setDestinationAddress] = useState(null);

  const [destinationCoordinates, setDestinationCoordinates] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  console.log("check directions", SpeedReport);

  const handleDirectionsResponse = (response) => {
    if (response !== null) {
      setDirections(response);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (SpeedReport && open) {
      setOriginCoord(
        SpeedReport?.rawData?.lat_2 + "," + SpeedReport?.rawData?.long_2
      );
      setDestinationCoordinates(
        SpeedReport?.rawData?.lat_1 + "," + SpeedReport?.rawData?.long_1
      );
      setOriginAddress(SpeedReport?.rawData?.origin);
      setDestinationAddress(SpeedReport?.rawData?.destinationLocation);
    }
  }, [SpeedReport, open]);

  useEffect(() => {
    const apiUrlMap = "/api/location/getLocationDetails";
    const mapBody = {
      url: `https://maps.googleapis.com/maps/api/directions/json?origin=${originCoord}&destination=${destinationCoordinates}&mode=driving&key=${MAP_KEY}&region=india`,
    };
    async function GetGoogleMapData() {
      try {
        setLoading(true); // Set loading to true when starting the API call
        const response = await axiosInstance.post(apiUrlMap, mapBody);
        const directionsData = response.data?.routes[0];
        setDirections(directionsData);
        console.log("Check response", response);
        if (response) {
          setLoading(false);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    }
    if (originCoord && destinationCoordinates && open) {
      GetGoogleMapData();
    }
  }, [originCoord, open, destinationCoordinates]);

  function FacebookCircularProgress(props) {
    return (
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: "#5932EA",
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }

  return (
    <>
      <CustomTooltip title={"View Profile"}>
        <IconButton onClick={handleClickOpen}>
          <AiOutlineEye />
        </IconButton>
      </CustomTooltip>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography variant="h6" fontSize={18}>
            Map view of speed {SpeedReport.currentValue} Kmph / Distance{" "}
            {SpeedReport?.rawData?.distance} / Time{" "}
            {dayjs(SpeedReport?.createdAt).format("h:mm A")} / Date{" "}
            <span>{dayjs(SpeedReport?.createdAt).format("DD-MM-YYYY")}</span>
          </Typography>
        </BootstrapDialogTitle>

        <DialogContent>
          <Grid container mt={4}>
            <LoadScript googleMapsApiKey={MAP_KEY} ref={mapRef}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                // center={currentLocation ? currentLocation : { lat: 0, lng: 0 }}
              >
                {loading ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "400px" }}
                  >
                    <Grid item>
                      <FacebookCircularProgress />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {/*===================================================================> Origin Marker  */}
                    <Marker
                      position={{
                        lat: SpeedReport?.rawData?.lat_2,
                        lng: SpeedReport?.rawData?.long_2,
                      }}
                      title="Origin"
                      onClick={() => handleMarkerClick("origin")}
                    >
                      {selectedMarker === "origin" && (
                        <InfoWindow
                          position={{
                            lat: SpeedReport?.rawData?.lat_2,
                            lng: SpeedReport?.rawData?.long_2,
                          }}
                          onCloseClick={() => setSelectedMarker(null)}
                        >
                          <>
                            <h4>Origin Location</h4>
                            <h4>
                              Time{" "}
                              {dayjs(SpeedReport?.rawData?.originTime).format(
                                "h:mm A"
                              )}
                            </h4>
                            <h4>
                              {`${SpeedReport?.rawData?.lat_2},  ${SpeedReport?.rawData?.long_2}  `}
                            </h4>
                            <h4> {originAddress}</h4>
                          </>
                        </InfoWindow>
                      )}
                    </Marker>
                    <Marker
                      position={{
                        lat: SpeedReport?.rawData?.lat_1,
                        lng: SpeedReport?.rawData?.long_1,
                      }}
                      onClick={() => handleMarkerClick("destination")}
                    >
                      {selectedMarker === "destination" && (
                        <InfoWindow
                          position={{
                            lat: SpeedReport?.rawData?.lat_1,
                            lng: SpeedReport?.rawData?.long_1,
                          }}
                          onCloseClick={() => setSelectedMarker(null)}
                        >
                          <div>
                            <h4>Destination</h4>
                            <h4>
                              Time{" "}
                              {dayjs(
                                SpeedReport?.rawData?.destinationTime
                              ).format("h:mm A")}
                            </h4>
                            <h4>
                              {`${SpeedReport?.rawData?.lat_1},  ${SpeedReport?.rawData?.long_1}  `}
                            </h4>
                            <h4>{destinationAddress} </h4>
                          </div>
                        </InfoWindow>
                      )}{" "}
                    </Marker>
                    {/*===================================================================> Polyline Show Grern Color   */}
                    {directions && (
                      <DirectionsRenderer
                        options={{
                          directions: directions,
                          polylineOptions: {
                            strokeColor: "green",
                            strokeOpacity: 0.8,
                            strokeWeight: 5,
                          },
                          suppressMarkers: true, // Hide the default "A" and "B" markers
                        }}
                      />
                    )}

                    {/* DirectionsService */}
                    <DirectionsService
                      options={{
                        origin: {
                          lat:
                            directions &&
                            directions?.legs &&
                            directions?.legs[0]?.start_location?.lat,
                          lng:
                            directions &&
                            directions.legs &&
                            directions?.legs[0]?.start_location?.lng,
                        },
                        destination: {
                          lat:
                            directions &&
                            directions?.legs &&
                            directions?.legs[0]?.end_location?.lat,
                          lng:
                            directions &&
                            directions.legs &&
                            directions?.legs[0]?.end_location?.lng,
                        },
                        travelMode: "DRIVING",
                      }}
                      position={originCoord}
                      callback={handleDirectionsResponse}
                    />
                  </>
                )}
              </GoogleMap>
            </LoadScript>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ mr: 1.4, mt: 1 }}>
          <Button
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={handleClose}
            style={{
              color: "red",
              backgroundColor: "#fff",
              border: `1px solid ${"red"}`,
              "&:hover": {
                backgroundColor: "red" || "#fff",
                color: "white" || "red",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
