import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Grid,
  Toolbar,
  IconButton,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

// core component
import { AuthContext } from "../Context/AuthContext";
//React Icons
import { AiOutlineLogout } from "react-icons/ai";
import { DiHtml5DeviceAccess } from "react-icons/di";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ShipmentLogo from "../assets/img/ShipmentLogo.png";

const Dashboard = () => {
  const navigate = useNavigate();
  const routes = [
    {
      invisible: false,
      path: "/shipment-management",
      name: "Shipment Management",
      icon: <ShareLocationIcon />,
    },
    {
      invisible: false,
      path: "/device-management",
      name: "Device Management",
      icon: <DiHtml5DeviceAccess />,
    },
  ];
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const drawerWidth = 260;
  const isMobile = useMediaQuery("(max-width:600px)"); // Define your mobile breakpoint here

  const { token, setToken } = useContext(AuthContext);
  const logout = () => {
    setToken(null);
    localStorage.removeItem("userData");
    navigate("/auth/signIn");
  };

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "fixed",
      height: "100vh",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          display: {
            xs: isMobile ? "flex" : "none",
            sm: "none",
            md: "none",
          },
        }}
        open={open}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "13px 0px",
            backgroundColor: "#fff",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), " +
              "0px 4px 5px 0px rgba(0,0,0,0.14), " +
              "0px 1px 10px 0px rgba(0,0,0,0.12)",

            px: [1],
          }}
        >
          {open ? (
            <>
              <img
                src={ShipmentLogo}
                alt="logo"
                style={{
                  width: "3em",
                  justifyContent: "flex-start",
                  marginTop: "7px",
                  borderRadius: "100px",
                }}
              />
              <Typography> Shipment Tracking </Typography>
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </>
          ) : (
            <Grid container justifyContent={"center"}>
              <IconButton
                edge="center"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          )}
        </Toolbar>
        {routes.map((route) => {
          if (route.invisible === false) {
            return (
              <ListItem
                button
                component={Link}
                to={route.path}
                onClick={() => {
                  setOpen(false);
                  setValue(route.activeIndex);
                }}
                sx={{ marginTop: "20px" }}
              >
                <ListItemIcon
                  className="SideBarIcon bluecolortypo "
                  style={{ paddingLeft: "8px" }}
                >
                  {route.icon}
                </ListItemIcon>
                <ListItemText disableTypography className="bluecolortypo">
                  {route.name}
                </ListItemText>
              </ListItem>
            );
          }
        })}
        <ListItem
          style={{
            position: "absolute",
            bottom: "0",
            marginBottom: "20px",
            cursor: "pointer",
          }}
        >
          <ListItemIcon
            className="SideBarIcon bluecolortypo"
            style={{ paddingLeft: "8px" }}
          >
            <AiOutlineLogout onClick={logout} />
          </ListItemIcon>
          <ListItemText
            onClick={logout}
            disableTypography
            className="bluecolortypo"
          >
            Logout
          </ListItemText>
        </ListItem>
      </Drawer>
    </>
  );
};

export default Dashboard;
