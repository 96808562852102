import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import {
  Grid,
  Table,
  TableSortLabel,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  Button,
  Box,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { FiDownload } from "react-icons/fi";
import { BsBatteryCharging } from "react-icons/bs";
import { axiosInstance } from "../../../component/axios/axiosInstance";
import SpeedLimit from "../Action/SpeedLimitDialog";
function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: "#5932EA",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
export default function SensorDetails({ profileData, shipmentDetails }) {
  const { id } = useParams();
  const tableContainerRef = useRef();
  const CurrentDate = dayjs();
  const createdDate = dayjs(shipmentDetails?.order?.createdAt).toDate();
  const deliveredTime = dayjs(shipmentDetails?.order?.deliveredTime).toDate();
  const currentDate = deliveredTime ? deliveredTime : CurrentDate.toDate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [sensorDetails, setSensorDetails] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleRequestSort = (event, key) => {
    const isAsc = orderBy === key && order === "asc";
    const newOrder = orderBy === key ? (isAsc ? "desc" : "asc") : "desc";
    setOrder(newOrder);
    setOrderBy(key);
  };
  const SortValue = order === "asc" ? 1 : -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };
  const SensorType = profileData?.name?.split(" ")[0]?.toLowerCase();

  const params = {
    search: {},
    filter: { order_ref: id },
    page: page + 1,
    limit: rowsPerPage,
    select: {
      name: 1,
      createdAt: 1,
      currentValue: 1,
      threshold: 1,
      isCharging: 1,
      isAlert: 1,
      "rawData.lat_1": 1,
      "rawData.long_1": 1,
      "rawData.lat_2": 1,
      "rawData.long_2": 1,
      "rawData.origin": 1,
      "rawData.destinationLocation": 1,
      "rawData.originTime": 1,
      "rawData.destinationTime": 1,
      "rawData.distance": 1,
    },
    startDate: new Date(dayjs(startDate ?? createdDate).startOf("day")),
    endDate: new Date(dayjs(endDate ?? currentDate).endOf("day")),
    sensorName: SensorType,
    sort: orderBy == "" ? { createdAt: -1 } : { [orderBy]: SortValue },
  };
  const apiUrl = `/api/sensor/fetchSensorData/`;
  const data = sensorDetails?.sensor?.result;

  async function GetSensorDetails() {
    try {
      setLoading(true);
      const response = await axiosInstance.get(apiUrl, { params });
      setSensorDetails(response.data);
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (orderBy && order && id) {
      GetSensorDetails();
    } else {
      GetSensorDetails();
    }
  }, [page, rowsPerPage, SortValue, id]);
  useEffect(() => {
    if (startDate) {
      GetSensorDetails();
    } else if (endDate) {
      GetSensorDetails();
    }
  }, [startDate, endDate]);
  const handleData = (data, datatype) => {
    if (datatype == "startDate") {
      setStartDate(data);
    } else if (datatype == "endDate") {
      setEndDate(data);
    }
  };

  const handleExport = () => {
    const modifiedData = data?.map((row) => {
      const unitMappings = {
        "Battery Sensor": "%",
        "Orientation Sensor": "°",
        "G-Force Sensor": "G",
        "Temperature Sensor": "°C",
        "Humidity Sensor": "%",
      };
      const unit = unitMappings[row?.name] || "";
      const Time = dayjs(row?.createdAt).format("h:mm A");
      const Date = dayjs(row?.createdAt).format("DD-MM-YYYY");
      const SensorName = row?.name;
      const ThresholdValue = `${row?.threshold ?? "--"} ${
        row?.threshold ? unit : ""
      }`;
      const Value = `${row?.currentValue} ${
        row?.currentValue === 0 ? "" : row?.currentValue && unit
      }`;
      return {
        Time,
        Date,
        SensorName,
        ThresholdValue,
        Value,
      };
    });
    const csvData = [];
    const tableHeading = "Manage Sensor Logs";
    csvData.push([[], [], tableHeading, [], []]);
    csvData.push([]);
    const headerRow = [
      "Time",
      "Date",
      "Sensor Name",
      "Threshold Value",
      "Value",
    ];
    csvData.push(headerRow);
    modifiedData.forEach((row) => {
      const rowData = [
        row.Time,
        row.Date,
        row.SensorName,
        row.ThresholdValue,
        row.Value,
      ];
      csvData.push(rowData);
    });

    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Sensor Report.csv");
  };
  useEffect(() => {
    if (tableContainerRef && tableContainerRef.current) {
      const tableContainer = tableContainerRef.current;
      // Store the current scroll position
      const scrollPosition = tableContainer.scrollTop;

      // After loading data, restore the scroll position
      tableContainer.scrollTop = scrollPosition;
    }
  }, [page, rowsPerPage, tableContainerRef]); // Add other dependencies if needed

  return (
    <>
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <Typography variant="h6">{profileData?.name}</Typography>
          <Typography variant="subtitle2" pt={2}>
            Showing {sensorDetails?.sensor?.result?.length} of{" "}
            {sensorDetails?.sensor?.totalCount} {profileData?.name}
          </Typography>{" "}
        </Grid>

        <Grid item mt={3}>
          <Grid container>
            <Grid item sx={{ border: "1px solid #ddd", borderRadius: "4px" }}>
              <Grid container>
                <Grid item sx={{ borderRight: "1px solid #ddd" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputFormat="dd/MM/yyyy"
                      value={startDate ?? createdDate}
                      onChange={(e) => {
                        handleData(e, "startDate");
                      }}
                      minDate={createdDate}
                      maxDate={currentDate}
                      renderInput={(params) => (
                        <TextField
                          sx={{ maxWidth: "150px" }}
                          variant="filled"
                          size="small"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Start date",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="End Date"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      inputFormat="dd/MM/yyyy"
                      value={endDate ?? currentDate}
                      onChange={(e) => {
                        handleData(e, "endDate");
                      }}
                      minDate={startDate}
                      maxDate={currentDate}
                      renderInput={(params) => (
                        <TextField
                          sx={{ maxWidth: "150px" }}
                          variant="filled"
                          size="small"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Start date",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>{" "}
            <Grid item ml={3}>
              <Button
                variant="contained"
                size="large"
                onClick={handleExport}
                style={{
                  color: "#fff",
                  backgroundColor: "#5932EA",
                  border: `1px solid ${"#ddd"}`,
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "white",
                  },
                }}
                startIcon={<FiDownload />}
              >
                Download Report
              </Button>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "400px" }}
        >
          <Grid item>
            <FacebookCircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          <TableContainer
            ref={tableContainerRef}
            className="mt-20px table-container"
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              className="mt-20"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className="tablecell-bg"
                    sx={{ padding: "14px 16px" }}
                  >
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={orderBy === "name" ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, "name")}
                    >
                      Sensor Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="tablecell-bg">Value</TableCell>
                  <TableCell className="tablecell-bg">Threshold</TableCell>
                  <TableCell className="tablecell-bg">
                    <TableSortLabel
                      active={orderBy === "createdAt"}
                      direction={orderBy === "createdAt" ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, "createdAt")}
                    >
                      Date & Time
                    </TableSortLabel>
                  </TableCell>
                  {SensorType === "speed" && (
                    <TableCell className="tablecell-bg"> Action </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {sensorDetails?.sensor?.result?.map((data, index) => {
                  const unitMappings = {
                    "Battery Status": "%",
                    "G-Force Sensor": "G",
                    "Temperature Sensor": "°C",
                    "Humidity Sensor": "%",
                    "Vibration Sensor": "m/s2",
                    Speed: "Kmph",
                  };
                  const unit = unitMappings[data?.name] || "";
                  return (
                    <TableRow hover className="clickable-row" key={index}>
                      <TableCell className="tablecrow-bg">
                        {data?.name}
                      </TableCell>

                      <TableCell className="tablecrow-bg ">
                        {data?.isCharging == true ? (
                          <BsBatteryCharging
                            style={{ transition: "" }}
                            fontSize={30}
                          />
                        ) : (
                          <span
                            className={
                              data?.currentValue === "Weak Signal"
                                ? "tablecrow-cell-bg-orange"
                                : data?.isAlert
                                ? "tablecrow-cell-bg-red"
                                : "tablecrow-cell-bg-green"
                            }
                          >
                            {data?.currentValue} {unit}
                          </span>
                        )}
                      </TableCell>
                      <TableCell className="tablecrow-bg ">
                        {data?.threshold == 0
                          ? ""
                          : `${data?.threshold ?? "--"} `}
                      </TableCell>

                      <TableCell className="tablecrow-cell-bg ">
                        {dayjs(data?.createdAt).format("h:mm A")},
                        <br />
                        <span className="tablecell-bg">
                          {dayjs(data?.createdAt).format("DD-MM-YYYY")}
                        </span>
                      </TableCell>
                      {SensorType === "speed" && (
                        <TableCell className="tablecrow-cell-bg ">
                          <SpeedLimit SpeedReport={data} />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {sensorDetails?.sensors?.length < 1 && (
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              sx={{ height: "40vh" }}
            >
              <Typography>No Data Found</Typography>
            </Grid>
          )}
          <TablePagination
            rowsPerPageOptions={[
              10,
              25,
              50,
              100,
              200,
              { label: "All", value: 10000 },
            ]}
            component="div"
            count={sensorDetails?.sensor?.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
}
