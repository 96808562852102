import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  Button,
  Stack,
  Tabs,
  Tab,
  Pagination,
} from "@mui/material";
import notificationImg from "../../assets/img/notification.gif";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { axiosInstance } from "../../component/axios/axiosInstance";
import ClearAll from "./ClearAllNotification";
import ReadAll from "./ReadAllNotification";
import useNotification from "../../component/NotificationDropDown/alertContext";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Notification() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageRead, setPageRead] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalReadPages, setTotalReadPages] = useState(1);
  const [value, setValue] = React.useState(0);

  const apiUrl = `/api/alert/fetchnotification`;
  const readapiUrl = `/api/alert/read`;
  const params = {
    search: "",
    filter: { status: value == 0 ? 1 : 0 },
    page: value == 0 ? page : pageRead,
    limit: 10,
    select: { name: 1, order_ref: 1, createdAt: 1, sensor: 1 },
    startDate: "",
    endDate: "",
    sort: { createdAt: -1 },
  };

  const { alertDetails, GetAlertDetails } = useNotification(params, apiUrl);

  const allNotification = alertDetails?.notifications?.result;
  const totalLength = alertDetails?.notifications?.totalCount;
  useEffect(() => {
    if (totalLength) {
      const length = totalLength ?? 0;
      setTotalReadPages(Math.ceil(length / 10));
      setTotalPages(Math.ceil(length / 10));
    }
  }, [totalLength]);

  const changeReadStatus = async (a) => {
    const Data = { alertId: [a?._id] };
    const selectedTab = 3;
    const newId = a?.order_ref?._id;
    const newPath = `/shipment-details/${newId}?tab=${selectedTab}`;
    try {
      const response = await axiosInstance.put(readapiUrl, Data);
      if (response) {
        GetAlertDetails();
        navigate(newPath);
      }
    } catch (error) {
      console.error("API Error:", error);
      navigate(newPath);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlePagination = (page) => {
    setPage(page);
  };
  const handlePaginationRead = (page) => {
    setPageRead(page);
  };
  useEffect(() => {
    if (value === 0 || value === 1 || pageRead || page) {
      GetAlertDetails();
    }
  }, [value, pageRead, page]);

  return (
    <>
      <Tabs
        className="width100 mt-10"
        centered
        value={value}
        onChange={handleChange}
      >
        <Tab label="Unread" className="TabChangesDevice" />
        <Tab label="Read" className="TabChangesDevice" />
      </Tabs>

      <TabPanel value={value} index={0} className="width100">
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Notification</Typography>
            <Typography variant="subtitle2" pt={2}>
              Showing {alertDetails?.notifications?.result?.length} of{" "}
              {alertDetails?.notifications?.totalCount} notifications
            </Typography>{" "}
          </Grid>
          <Grid item>
            <ReadAll GetAlertDetails={GetAlertDetails} value={value} />
          </Grid>
        </Grid>
        {alertDetails && alertDetails?.notifications?.result?.length > 0 ? (
          allNotification?.map((mData, i) => {
            return (
              <Grid
                container
                mt={3}
                item
                key={i}
                className={"notification-unread"}
                onClick={() => changeReadStatus(mData)}
              >
                <Grid container p={2} gap="5px" direction="column">
                  <Grid container justifyContent={"space-between"}>
                    <Grid item>
                      <Typography className="tablecrow-cell-bg  ">
                        {mData?.order_ref?.name}
                      </Typography>
                      <Typography className="tablecrow-cell-bg  ">
                        {mData?.name}
                      </Typography>
                      <Typography className="tablecell-bg">
                        {mData?.sensor}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="tablecell-bg">
                        {dayjs(mData?.createdAt).format("h:mm A")},
                        <br />
                        <span className="tablecell-bg">
                          {dayjs(mData?.createdAt).format("DD-MM-YYYY")}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", height: "80vh" }}
            direction="column"
          >
            <Grid container justifyContent="center">
              <img
                src={notificationImg}
                alt="notification"
                width={150}
                height={150}
              />
            </Grid>

            <Typography className="fs20px">
              No notifications to show yet
            </Typography>
            <Typography className=" fs16px" sx={{ color: "#6D787D" }}>
              You’ll see useful information here soon. Stay tuned!
            </Typography>
          </Grid>
        )}
        <Grid
          container
          justifyContent="center"
          mt={2}
          className="mt-10 pagination-assign"
        >
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              variant="outlined"
              color="secondary"
              onChange={(event, page) => handlePagination(page)}
              showFirstButton
              showLastButton
            />
          </Stack>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1} className="width100">
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="h6">Notification</Typography>
            <Typography variant="subtitle2" pt={2}>
              Showing {alertDetails?.notifications?.result?.length} of{" "}
              {alertDetails?.notifications?.totalCount} notifications
            </Typography>{" "}
          </Grid>
          <Grid item>
            <ClearAll GetAlertDetails={GetAlertDetails} value={value} />
          </Grid>
        </Grid>

        {alertDetails && alertDetails?.notifications?.result?.length > 0 ? (
          allNotification?.map((mData, i) => {
            return (
              <Grid
                container
                mt={3}
                item
                key={i}
                className={"notification-read"}
                onClick={() => changeReadStatus(mData)}
              >
                <Grid container p={2} gap="5px" direction="column">
                  <Grid container justifyContent={"space-between"}>
                    <Grid item>
                      <Typography className="tablecrow-cell-bg  ">
                        {mData?.order_ref?.name}
                      </Typography>
                      <Typography className="tablecrow-cell-bg  ">
                        {mData?.name}
                      </Typography>
                      <Typography className="tablecell-bg">
                        {mData?.sensor}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="tablecell-bg">
                        {dayjs(mData?.createdAt).format("h:mm A")},
                        <br />
                        <span className="tablecell-bg">
                          {dayjs(mData?.createdAt).format("DD-MM-YYYY")}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", height: "80vh" }}
            direction="column"
          >
            <Grid container justifyContent="center">
              <img
                src={notificationImg}
                alt="notification"
                width={150}
                height={150}
              />
            </Grid>

            <Typography className="fs20px">
              No notifications to show yet
            </Typography>
            <Typography className=" fs16px" sx={{ color: "#6D787D" }}>
              You’ll see useful information here soon. Stay tuned!
            </Typography>
          </Grid>
        )}
        <Grid
          container
          justifyContent="center"
          mt={2}
          className="mt-10 pagination-assign"
        >
          <Stack spacing={2}>
            <Pagination
              count={totalReadPages}
              page={pageRead}
              variant="outlined"
              color="secondary"
              onChange={(event, page) => handlePaginationRead(page)}
              showFirstButton
              showLastButton
            />
          </Stack>
        </Grid>
      </TabPanel>
    </>
  );
}
