import React from "react";
import { useState } from "react";
import { Grid, Typography, IconButton, Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";

import { FcOpenedFolder } from "react-icons/fc";
import { FcDownload } from "react-icons/fc";
import { axiosInstance } from "../../../../component/axios/axiosInstance";
import { useEffect } from "react";

export default function FilesTab() {
  const { id } = useParams();
  const apiUrl = `/api/order/fetchAvailableDeviceLogFiles/${id}`;
  const apiFileUrl = `/api/order/downloadDeviceLogFile`;

  const [logsFiles, setLogsFiles] = useState(null);
  const [downloadFile, setDownloadFile] = useState(null);

  console.log("Check logsFiles", logsFiles);
  async function GetLogsFiles() {
    try {
      const response = await axiosInstance.get(apiUrl);
      setLogsFiles(response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  async function GetDownloadFiles(item) {
    const params = {
      orderId: id,
      fileName: item,
    };

    try {
      const response = await axiosInstance.get(apiFileUrl, {
        params,
        responseType: "blob", // Set the response type to blob
      });
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);
      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = downloadFile; // Set the desired file name
      document.body.appendChild(a);
      a.click();
      // Clean up the URL object
      window.URL.revokeObjectURL(url);
      setDownloadFile("");
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  useEffect(() => {
    GetLogsFiles();
  }, []);
  useEffect(() => {
    if (downloadFile) {
      GetDownloadFiles(downloadFile);
    }
  }, [downloadFile]);

  const downloadFiles = (e, item) => {
    setDownloadFile(item);
  };

  return (
    <>
      <Typography variant="h6">Manage Files</Typography>
      <Grid container mt={2} gap={3}>
        {logsFiles?.files?.length > 0 ? (
          logsFiles.files.map((item, index) => (
            <Grid item className="logs-files-main" key={index}>
              <Grid item className="logs-dwd-icon">
                <IconButton onClick={(e) => downloadFiles(e, item)}>
                  <FcDownload fontSize={25} />
                </IconButton>
              </Grid>
              <FcOpenedFolder className="logs-icon-folder" />
              <Grid item sx={{ marginTop: "-10px" }}>
                <Tooltip title={item}>
                  <Typography
                    className="text-width"
                    align="center"
                    variant="subtitle1"
                  >
                    {item}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid
            container
            justifyContent={"center"}
            alignContent={"center"}
            sx={{ height: "40vh" }}
          >
            <Typography variant="subtitle1" align="center">
              No files available
            </Typography>{" "}
          </Grid>
        )}
      </Grid>
    </>
  );
}
