import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Tabs, Tab } from "@mui/material";

//core component that
import OverviewTab from "./OverviewTab";
import FilesTab from "./FilesTab";
import FetchShipment from "../../Action/FetchShipmentData";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Testing({ shipmentDetails, GetshipmentDetails }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    localStorage.setItem("selectedLogdTab", newValue.toString());
    setValue(newValue);
  };
  useEffect(() => {
    const savedTabValue = localStorage.getItem("selectedLogdTab");
    if (savedTabValue !== null) {
      setValue(parseInt(savedTabValue, 10));
    }
  }, []);

  return (
    <>
      <Grid container mt={1} sx={{ position: "relative" }}>
        {/* <Grid
          item
          sx={{ position: "absolute", right: 0, top: 10, zIndex: 1111 }}
        >
          <FetchShipment
            shipmentDetails={shipmentDetails}
            GetshipmentDetails={GetshipmentDetails}
          />
        </Grid> */}
        <Grid item className="width100">
          <Tabs centered value={value} onChange={handleChange}>
            <Tab label="Overview" className="TabChangesDevice" />
            <Tab label="Files" className="TabChangesDevice" />
          </Tabs>
          <TabPanel value={value} index={0} className="width100">
            <OverviewTab />
          </TabPanel>
          <TabPanel value={value} index={1} className="width100">
            <FilesTab />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
}
