import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import {
  Grid,
  Table,
  TableSortLabel,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  Button,
  Box,
} from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { axiosInstance } from "../../../component/axios/axiosInstance";
import { FiDownload } from "react-icons/fi";

import { saveAs } from "file-saver";
import Papa from "papaparse";
function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: "#5932EA",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
export default function LocationDetails() {
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true); // Added loading state

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [locationDetails, setLocationDetails] = useState(null);
  const tableContainerRef = useRef();
  const data = locationDetails?.sensor?.result;
  const handleRequestSort = (event, key) => {
    const isAsc = orderBy === key && order === "asc";
    const newOrder = orderBy === key ? (isAsc ? "desc" : "asc") : "desc";
    setOrder(newOrder);
    setOrderBy(key);
  };
  const SortValue = order === "asc" ? 1 : -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };
  const params = {
    search: {},
    filter: { order_ref: id },
    page: page + 1,
    limit: rowsPerPage,
    select: {},
    startDate: "",
    endDate: "",
    sensorName: "gps",
    sort: orderBy == "" ? { createdAt: -1 } : { [orderBy]: SortValue },
  };
  const apiUrl = `/api/sensor/fetchSensorData`;

  async function GetSensorDetails() {
    try {
      setLoading(true);
      const response = await axiosInstance.get(apiUrl, { params });
      setLocationDetails(response.data);
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (orderBy && order && id) {
      GetSensorDetails();
    } else {
      GetSensorDetails();
    }
  }, [page, rowsPerPage, SortValue, id]);

  const handleExport = () => {
    const modifiedData = data?.map((row) => {
      const Time = dayjs(row?.createdAt).format("h:mm A");
      const Date = dayjs(row?.createdAt).format("DD-MM-YYYY");
      const LocationName = row?.location?.name;
      const Gps_Signal = row?.gps_signal;
      const Coordinates = `${row?.location?.coordinates[0]}, ${row?.location?.coordinates[1]}`;
      return {
        Time,
        Date,
        LocationName,
        Coordinates,
        Gps_Signal,
      };
    });
    const csvData = [];
    const tableHeading = "                   Manage Location              ";
    csvData.push([[], [], tableHeading, [], []]);
    csvData.push([]);
    const headerRow = [
      "Time",
      "Date",
      "LocationName",
      "Coordinates",
      "Gps_Signal",
    ];
    csvData.push(headerRow);
    modifiedData.forEach((row) => {
      const rowData = [
        row.Time,
        row.Date,
        row.LocationName,
        row.Coordinates,
        row.Gps_Signal,
      ];
      csvData.push(rowData);
    });

    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Location Report.csv");
  };

  useEffect(() => {
    if (tableContainerRef && tableContainerRef.current) {
      const tableContainer = tableContainerRef.current;
      // Store the current scroll position
      const scrollPosition = tableContainer.scrollTop;
      // After loading data, restore the scroll position
      tableContainer.scrollTop = scrollPosition;
    }
  }, [page, rowsPerPage, tableContainerRef]); // Add other dependencies if needed

  return (
    <>
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <Typography variant="h6">Manage Location</Typography>
          <Typography variant="subtitle2" pt={2}>
            Showing {locationDetails?.sensor?.result?.length} of{" "}
            {locationDetails?.sensor?.totalCount} Location
          </Typography>
        </Grid>
        <Grid item mt={3}>
          <Button
            variant="contained"
            size="medium"
            onClick={handleExport}
            style={{
              color: "#fff",
              backgroundColor: "#5932EA",
              border: `1px solid ${"#ddd"}`,
              "&:hover": {
                backgroundColor: "#fff",
                color: "white",
              },
            }}
            startIcon={<FiDownload />}
          >
            Download Report
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "400px" }}
        >
          <Grid item>
            <FacebookCircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          <TableContainer
            ref={tableContainerRef}
            className="mt-20px table-container"
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              className="mt-20"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className="tablecell-bg"
                    sx={{ padding: "14px 16px" }}
                  >
                    Location Name
                  </TableCell>
                  <TableCell className="tablecell-bg"> Gps_Signal</TableCell>
                  <TableCell className="tablecell-bg">Value</TableCell>
                  <TableCell className="tablecell-bg">
                    <TableSortLabel
                      active={orderBy === "createdAt"}
                      direction={orderBy === "createdAt" ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, "createdAt")}
                    >
                      Date & Time
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locationDetails?.sensor?.result?.map((data, index) => {
                  return (
                    <TableRow hover className="clickable-row" key={index}>
                      <TableCell className="tablecrow-bg ">
                        {data?.currentValue ?? "--"}
                      </TableCell>
                      <TableCell className="tablecell-bg">
                        <span
                          size="small"
                          className={
                            data?.gps_signal === "lost" ||
                            data?.gps_signal === "weak"
                              ? "status-orange-text"
                              : "status-complete-text"
                          }
                        >
                          {data?.gps_signal}
                        </span>
                      </TableCell>

                      <TableCell className="tablecrow-cell-bg">
                        {data?.gps_signal === "lost"
                          ? "--"
                          : data?.gps_signal === "weak"
                          ? "--"
                          : `${data?.location?.coordinates[0]}, ${data?.location?.coordinates[1]}`}
                      </TableCell>

                      <TableCell className="tablecrow-cell-bg ">
                        {dayjs(data?.createdAt).format("h:mm A")},
                        <br />
                        <span className="tablecell-bg">
                          {dayjs(data?.createdAt).format("DD-MM-YYYY")}
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {locationDetails?.sensor.totalCount == 0 && (
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              sx={{ height: "40vh" }}
            >
              <Typography>No Data Found</Typography>
            </Grid>
          )}
          <TablePagination
            rowsPerPageOptions={[
              10,
              25,
              50,
              100,
              200,
              { label: "All", value: 10000 },
            ]}
            component="div"
            count={locationDetails?.sensor?.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
}
