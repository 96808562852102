import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import {
  DialogTitle,
  Typography,
  Button,
  TextField,
  Grid,
  DialogActions,
  DialogContent,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { BsPlusCircle } from "react-icons/bs";
import { FiSend } from "react-icons/fi";

import CancelAlertDialog from "../../../component/CustomDialog/CancelAlert";
import { axiosInstance } from "../../../component/axios/axiosInstance";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AddShipment({ fetchData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const registerOptions = { required: true };
  const [formStateData, setFormState] = useState({
    shipmentUID: "",
    originName: "",
    deviceUid: "",
    shipmentName: "",
    originLattitude: "",
    destination: "",
    macId: "",
  });
  const [deviceFormValues, setDeviceFormValues] = useState({
    deviceName: null,
  });

  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");
  // ===================== SnackBar ==================== //
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const vertical = "top";
  const horizontal = "center";
  const openSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleClearDeviceName = () => {
    setDeviceFormValues((prevState) => ({
      ...prevState,
      deviceName: null,
    }));
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormState((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
    setFormState((prevState) => ({
      ...prevState,
      deviceUid: "",
      deviceName: "",
      macId: "",
    }));
  };

  const handleAddDevice = async () => {
    let body = {
      name: formStateData.deviceName,
      deviceUid: formStateData.deviceUid,
      macId: formStateData.macId,
    };
    try {
      const resp = await axiosInstance.post("/api/device/addDevice", body);
      openSnackbar(resp?.data?.msg, "success");
      fetchData();
      handleClose();
    } catch (error) {
      openSnackbar(error?.response?.data?.msg || "An error occurred.", "error");
    }
  };

  return (
    <>
      <Snackbar
        sx={{ marginTop: "60px" }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={closeSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Button
        variant="contained"
        size="medium"
        onClick={handleClickOpen}
        style={{
          color: "#fff",
          backgroundColor: "#5932EA",
          border: `1px solid ${"#ddd"}`,
          "&:hover": {
            backgroundColor: "#fff",
            color: "white",
          },
        }}
        startIcon={<BsPlusCircle />}
      >
        Create New Devices
      </Button>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            padding: "12px 20px",
            color: "white",
            backgroundColor: "#5932EA",
            m: 0,
          }}
        >
          <Typography variant="h6" fontSize={18}>
            New Tracking Device
          </Typography>
        </DialogTitle>

        <form onSubmit={handleSubmit(handleAddDevice)}>
          <DialogContent dividers>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="deviceName"
                  label="Device Name"
                  variant="outlined"
                  size="small"
                  className=" mt-20px "
                  {...register("deviceName", registerOptions)}
                  name="deviceName"
                  error={!formStateData.deviceName && !!errors.deviceName}
                  helperText={
                    !formStateData.deviceName
                      ? errors.deviceName && "Device Name   is required"
                      : ""
                  }
                  value={formStateData.deviceName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="deviceUid"
                  label="Device UID"
                  variant="outlined"
                  size="small"
                  className=" mt-20px "
                  {...register("deviceUid", registerOptions)}
                  name="deviceUid"
                  error={!formStateData.deviceUid && !!errors.deviceUid}
                  helperText={
                    !formStateData.deviceUid
                      ? errors.deviceUID && "Device UID  is required"
                      : ""
                  }
                  value={formStateData.deviceUid}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <TextField
              fullWidth
              id="macId"
              label="Mac Id"
              variant="outlined"
              size="small"
              className=" mt-20px "
              type="text"
              {...register("macId", registerOptions)}
              name="macId"
              error={!formStateData.macId && !!errors.macId}
              helperText={
                !formStateData.macId
                  ? errors.macId && "Mac Id  is required"
                  : ""
              }
              value={formStateData.macId}
              onChange={handleInputChange}
            />
            <DialogActions className="mt-20px">
              <div className="mr-10px">
                <CancelAlertDialog handleCloseFirst={handleClose} />
              </div>
              <Button
                variant="contained"
                type="submit"
                startIcon={<FiSend />}
                style={{
                  color: "#fff",
                  backgroundColor: "#5932EA",
                  border: `1px solid ${"#ddd"}`,
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "white",
                  },
                }}
                // disabled={divisionAddReponce.loading === true && true}
              >
                Submit
                {/* <CircularProgress className="loading-for-btn" size={24} /> */}
              </Button>
            </DialogActions>{" "}
          </DialogContent>{" "}
        </form>
      </BootstrapDialog>
    </>
  );
}
