import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import {
  Grid,
  Table,
  TableSortLabel,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  Box,
} from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { axiosInstance } from "../../../../component/axios/axiosInstance";

// ** React Icons
import { BsBatteryCharging } from "react-icons/bs";
function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: "#5932EA",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}
export default function Home() {
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [logsDetails, setLogsDetails] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state

  const handleRequestSort = (event, key) => {
    const isAsc = orderBy === key && order === "asc";
    const newOrder = orderBy === key ? (isAsc ? "desc" : "asc") : "desc";
    setOrder(newOrder);
    setOrderBy(key);
  };
  const SortValue = order === "asc" ? 1 : -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };
  const params = {
    search: {},
    filter: {},
    page: page + 1,
    limit: rowsPerPage,
    select: {
      name: 1,
      createdAt: 1,
      currentValue: 1,
      isCharging: 1,
      isAlert: 1,
    },
    startDate: "",
    endDate: "",
    sort: orderBy == "" ? { createdAt: -1 } : { [orderBy]: SortValue },
  };
  const apiUrl = `/api/order/fetchLogs/${id}`;

  async function GetLogsDetails() {
    try {
      setLoading(true);
      const response = await axiosInstance.get(apiUrl, { params });
      setLogsDetails(response.data);
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (orderBy && order && id) {
      GetLogsDetails();
    } else {
      GetLogsDetails();
    }
  }, [page, rowsPerPage, SortValue, id]);

  const tableContainerRef = useRef();

  useEffect(() => {
    if (tableContainerRef && tableContainerRef.current) {
      const tableContainer = tableContainerRef.current;

      // Store the current scroll position
      const scrollPosition = tableContainer.scrollTop;

      // After loading data, restore the scroll position
      tableContainer.scrollTop = scrollPosition;
    }
  }, [page, rowsPerPage, tableContainerRef]); // Add other dependencies if needed

  return (
    <>
      <Grid container justifyContent={"space-between"}>
        <Typography variant="h6">Manage Logs</Typography>

        <Typography variant="subtitle2" pt={2}>
          Showing {logsDetails?.logs?.result?.length} of{" "}
          {logsDetails?.logs?.totalCount} Logs
        </Typography>
      </Grid>
      {loading ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "400px" }}
        >
          <Grid item>
            <FacebookCircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          <TableContainer
            ref={tableContainerRef}
            className="mt-20px table-container"
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              className="mt-20"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className="tablecell-bg"
                    sx={{ padding: "14px 16px" }}
                  >
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={orderBy === "name" ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, "name")}
                    >
                      Log Name
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className="tablecell-bg">Value</TableCell>
                  <TableCell className="tablecell-bg">
                    <TableSortLabel
                      active={orderBy === "createdAt"}
                      direction={orderBy === "createdAt" ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, "createdAt")}
                    >
                      Date & Time
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logsDetails?.logs?.result?.map((item, index) => {
                  const unitMappings = {
                    "Battery Status": "%",
                    "G-Force Sensor": "G",
                    "Temperature Sensor": "°C",
                    "Humidity Sensor": "%",
                    "Low Battery Alert": "%",
                  };
                  const unit = unitMappings[item?.name] || "";
                  return (
                    <TableRow key={index} hover className="clickable-row">
                      <TableCell className="tablecrow-cell-bg">
                        {item?.name}
                      </TableCell>
                      <TableCell className="tablecrow-cell-bg">
                        {item?.isCharging == true ? (
                          <BsBatteryCharging
                            style={{ transition: "" }}
                            fontSize={30}
                          />
                        ) : (
                          <span
                            className={
                              item?.currentValue === "Weak Signal"
                                ? "tablecrow-cell-bg-orange"
                                : item?.isAlert
                                ? "tablecrow-cell-bg-red"
                                : "tablecrow-cell-bg-green"
                            }
                          >
                            {item?.currentValue} {unit}
                          </span>
                        )}
                      </TableCell>

                      <TableCell className="tablecrow-cell-bg ">
                        {dayjs(item?.createdAt).format("h:mm A")},
                        <br />
                        <span className="tablecell-bg">
                          {dayjs(item?.createdAt).format("DD-MM-YYYY")}
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {logsDetails?.logs?.result?.length < 1 && (
              <Grid
                container
                justifyContent={"center"}
                alignContent={"center"}
                sx={{ height: "40vh" }}
              >
                <Typography>No Data Found</Typography>
              </Grid>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              10,
              25,
              50,
              100,
              200,
              { label: "All", value: 100000 },
            ]}
            component="div"
            count={logsDetails?.logs?.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
}
