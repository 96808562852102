import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { Grid, InputAdornment, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
//core Comoponent
import axiosInstance from "../../component/axios/axiosInstance";
import { MdOutlineEmail } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Login = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  useEffect(() => {
    setValue("password", values.password);
  }, [values]);
  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };
  // ===================== SnackBar ==================== //
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const vertical = "top";
  const horizontal = "center";
  const openSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };
  const Login = async (event) => {
    const body = {
      username: email,
      password: values.password,
    };
    try {
      const resp = await axiosInstance.post("/api/user/login", body);
      localStorage.setItem("token", resp.data.token);
      navigate("/shipment-management");
      window.location.reload();
    } catch (err) {
      console.error(err.response);
      openSnackbar(
        err?.response?.data?.msg[0]?.message || err?.response?.data?.msg,
        "error"
      );
    }
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleChange = (propertyName) => (event) => {
    setValues({ ...values, [propertyName]: event.target.value });
  };

  const emailValidationPattern =
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const isEmailInvalid = !!email && !emailValidationPattern.test(email);
  const isEmailRequiredError = !email && errors.email;
  return (
    <>
      <Snackbar
        sx={{ marginTop: "60px" }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={closeSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div className="login-container">
        <form onSubmit={handleSubmit(Login)}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            className="mt60px"
          >
            <div className="login-box">
              <div
                style={{
                  width: "93%",
                  marginLeft: "auto",
                  marginTop: "3em",
                  marginBottom: "3em",
                  marginRight: "auto",
                }}
              >
                <Typography sx={{ fontWeight: "700", fontSize: "22px" }}>
                  Sign in to Shipment Tracking
                </Typography>
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Enter E-mail"
                  name="email"
                  variant="filled"
                  autoFocus={!email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdOutlineEmail fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: emailValidationPattern,
                      message: "Please enter a valid email address",
                    },
                  })}
                  helperText={
                    isEmailInvalid
                      ? "Please enter a valid email address"
                      : isEmailRequiredError
                      ? "Email is required"
                      : ""
                  }
                  error={isEmailInvalid || isEmailRequiredError}
                  size="small"
                  value={email}
                  onChange={handleInputChange}
                  sx={{
                    background: "#fff",
                    borderRadius: "6px",
                    mt: 3,
                  }}
                  InputLabelProps={{
                    style: { color: "#080605" },
                  }}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Enter Password"
                  id="password"
                  variant="filled"
                  size="small"
                  type={values.showPassword ? "text" : "password"}
                  {...register("password", {
                    required: true,
                  })}
                  helperText={
                    !values.password
                      ? errors.password && "Password is required"
                      : ""
                  }
                  error={!values.password && !!errors.password}
                  value={values.password}
                  onChange={handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleClickShowPassword}
                          aria-label="toggle password visibility"
                        >
                          {values.showPassword ? (
                            <AiOutlineEye />
                          ) : (
                            <AiOutlineEyeInvisible />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ background: "#fff", borderRadius: "6px", mt: 4 }}
                  InputLabelProps={{
                    style: { color: "#080605" },
                  }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{
                    mt: 4,
                    mb: 2,
                    backgroundColor: "#5932EA",
                    borderRadius: "6px",
                    fontWeight: "700",
                    textTransform: "capitalize",
                    fontSize: "18px",
                    ":hover": {
                      backgroundColor: "#5000EA",
                      opacity: 1,
                    },
                  }}
                >
                  Log In
                </Button>
              </div>
            </div>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default Login;
