import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Button,
} from "@mui/material";
import { BsBatteryCharging } from "react-icons/bs";

export default function overviewTable({
  AlertDetailsShow,
  shipmentDetails,
  SensorDetailsShow,
}) {
  return (
    <>
      <Grid container mt={3} mb={5} justifyContent={"space-between"}>
        <Grid item xs={12} sm={12} md={12} lg={5.82} className="overview-item">
          <Grid container justifyContent="space-between" p={2}>
            <Typography className="primary-color fs-20">Alerts</Typography>
            <Typography
              className="primary-color fs-20 cursor-point"
              onClick={() => AlertDetailsShow()}
            >
              See All
            </Typography>
          </Grid>
          <TableContainer className="mt-20px">
            <Table
              stickyHeader
              aria-label="sticky table"
              className="mt-20"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell className="tablecell-bg">Alert Name</TableCell>
                  <TableCell className="tablecell-bg">
                    Alert value
                  </TableCell>{" "}
                  <TableCell className="tablecell-bg">
                    Threshold Value
                  </TableCell>
                  <TableCell className="tablecell-bg">Time And date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shipmentDetails?.alerts?.map((data, index) => {
                  const unitMappings = {
                    "Battery Status": "%",
                    "G-Force Sensor": "G",
                    "Temperature Sensor": "°C",
                    "Humidity Sensor": "%",
                    "Vibration Sensor": "m/s2",
                    "Speed Status": "Kmph",
                  };
                  const unit = unitMappings[data?.sensor] || "";
                  return (
                    <>
                      <TableRow key={index} hover className="clickable-row">
                        <TableCell className="tablecrow-cell-bg ">
                          {data?.name}
                          <br />
                          <span className="tablecell-bg"> {data?.sensor}</span>
                        </TableCell>
                        <TableCell
                          className={
                            data?.alertValue === "Weak Signal"
                              ? "tablecrow-cell-bg-orange"
                              : "tablecrow-cell-bg-red"
                          }
                        >
                          {data?.alertValue}{" "}
                          {data?.alertValue == 0
                            ? ""
                            : data?.alertValue && unit}
                        </TableCell>{" "}
                        <TableCell className="tablecrow-cell-bg ">
                          {data?.threshold}{" "}
                          {data?.threshold == 0 ? "" : data?.threshold && unit}
                        </TableCell>
                        <TableCell className="tablecrow-cell-bg ">
                          {dayjs(data?.createdAt).format("h:mm A")},
                          <br />
                          <span className="tablecell-bg">
                            {dayjs(data?.createdAt).format("DD-MM-YYYY")}
                          </span>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {shipmentDetails?.alerts?.length < 1 && (
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              sx={{ height: "40vh" }}
            >
              <Typography>No Data Found</Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5.82} className="overview-item">
          <Grid container justifyContent="space-between" p={2}>
            <Typography className="primary-color fs-20 ">
              Sensors Status
            </Typography>
            <Typography
              className="primary-color fs-20 cursor-point "
              onClick={() => SensorDetailsShow()}
            >
              See All
            </Typography>
          </Grid>
          <TableContainer className="mt-20px">
            <Table
              stickyHeader
              aria-label="sticky table"
              className="mt-20"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell className="tablecell-bg">Sensor Name</TableCell>
                  <TableCell className="tablecell-bg">Current value</TableCell>
                  <TableCell className="tablecell-bg">
                    Threshold Value
                  </TableCell>
                  <TableCell className="tablecell-bg">Last Updated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shipmentDetails?.sensors?.map((data, index) => {
                  const unitMappings = {
                    "Battery Status": "%",
                    "G-Force Sensor": "G",
                    "Temperature Sensor": "°C",
                    "Humidity Sensor": "%",
                    "Vibration Sensor": "m/s2",
                    "Speed Status": "Kmph",
                  };
                  const unit = unitMappings[data?.name] || "";
                  return (
                    <>
                      <TableRow hover className="clickable-row" key={index}>
                        <TableCell className="tablecrow-bg">
                          {data?.name}
                        </TableCell>
                        <TableCell className="tablecrow-bg ">
                          {data?.isCharging == true ? (
                            <BsBatteryCharging
                              style={{ transition: "" }}
                              fontSize={30}
                            />
                          ) : (
                            <span
                              className={
                                data?.isAlert
                                  ? "tablecrow-cell-bg-red"
                                  : "tablecrow-cell-bg-green"
                              }
                            >
                              {data?.currentValue} {unit}
                            </span>
                          )}
                        </TableCell>
                        <TableCell className="tablecrow-bg ">
                          {data?.threshold == 0
                            ? "--"
                            : `${data?.threshold ?? "--"} ${unit ?? "--"}`}
                        </TableCell>
                        <TableCell className="tablecrow-cell-bg ">
                          {dayjs(data?.createdAt).format("h:mm A")},
                          <br />
                          <span className="tablecell-bg">
                            {dayjs(data?.createdAt).format("DD-MM-YYYY")}
                          </span>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {shipmentDetails?.sensors?.length < 1 && (
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              sx={{ height: "40vh" }}
            >
              <Typography>No Data Found</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
