import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AiOutlineDelete } from "react-icons/ai";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CustomTooltip from "../../../component/CustomTooltip/CustomTooltip";
import { axiosInstance } from "../../../component/axios/axiosInstance";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className="dialogtitle-bg-red" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          className="dialog-iconbtn"
          sx={{
            color: (theme) => theme.palette.common.white,
            backgroundColor: "transparent",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function CustomizedDialogs({ item, fetchData }) {
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("xs");
  // ===================== SnackBar ==================== //
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const vertical = "top";
  const horizontal = "center";
  const openSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteShipment = async () => {
    let body = {
      orderId: item?._id,
      status: -3,
    };
    try {
      const resp = await axiosInstance.put(
        "/api/order/updateOrderStatus",
        body
      );
      openSnackbar(resp?.data?.msg, "success");
      fetchData();
      handleClose();
    } catch (error) {
      openSnackbar(error?.response?.data?.msg || "An error occurred.", "error");
    }
  };

  return (
    <div>
      <Snackbar
        sx={{ marginTop: "60px" }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={closeSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CustomTooltip title={"Delete Shipment"}>
        <IconButton onClick={handleClickOpen}>
          <AiOutlineDelete />
        </IconButton>
      </CustomTooltip>

      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography variant="h6" color="primary.contrastText">
            Delete Shipment
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className="dialog-content">
          <Typography variant="h6">
            Are you sure you want to delete this shipment ?
          </Typography>
        </DialogContent>
        <DialogActions className="mt-5">
          <Button
            variant="contained"
            style={{
              color: "red",
              backgroundColor: "#fff",
              border: `1px solid ${"red"}`,
              "&:hover": {
                backgroundColor: "red" || "#fff",
                color: "white" || "red",
              },
            }}
            onClick={handleDeleteShipment}
          >
            YES{" "}
          </Button>
          <Button
            variant="contained"
            style={{
              color: "#044A70",
              backgroundColor: "#fff",
              border: `1px solid ${"#CCCCCC"}`,
              "&:hover": {
                backgroundColor: "#CCCCCC" || "transparent",
                color: "white" || "#CCCCCC",
              },
            }}
            onClick={handleClose}
          >
            {" "}
            NO{" "}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default CustomizedDialogs;
