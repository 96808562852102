import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Grid, Breadcrumbs, Tabs, Tab, Container, Chip } from "@mui/material";
import { emphasize, styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { axiosInstance } from "../../component/axios/axiosInstance";
import { useSearchParams } from "react-router-dom";

//core component that
import OverviewTab from "./ShipmentDetailTab/OverviewTab";
import AlertsTab from "./ShipmentDetailTab/AlertsTab";
import SensorsTab from "./ShipmentDetailTab/SensorsTab";
import LogsTab from "./ShipmentDetailTab/LogsTab/LogsTab";
import LocationTab from "./ShipmentDetailTab/LocationTab";
import AnalyticsTab from "./ShipmentDetailTab/AnalyticsTab";
import MapTab from "./ShipmentDetailTab/MapTab";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function handleClick(event) {
  event.preventDefault();
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});
export default function Testing() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [value, setValue] = React.useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [shipmentDetails, setShipmentDetails] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const selectedTab = parseInt(queryParams.get("tab"), 10); // Convert to a number with base 10
  const handleChange = (event, newValue) => {
    setValue(newValue);
    searchParams.set("tab", newValue);
    setSearchParams(searchParams);
  };

  // useEffect(() => {
  //   const savedTabValue = localStorage.getItem("selectedTab");
  //   if (savedTabValue !== null) {
  //     setValue(parseInt(savedTabValue, 10));
  //   }
  // }, []);
  useEffect(() => {
    setValue(Number.isNaN(selectedTab) ? 0 : selectedTab);
  }, [selectedTab]);

  const apiUrl = `/api/order/fetchSingleOrder/${id}`;

  async function GetshipmentDetails() {
    try {
      const response = await axiosInstance.get(apiUrl);
      setShipmentDetails(response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  useEffect(() => {
    if (id) {
      GetshipmentDetails();
    }
  }, [id]);
  return (
    <>
      <div role="presentation" style={{ marginTop: "100px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            label="Shipments Management"
            onClick={() => navigate("/shipment-management")}
            deleteIcon={<ExpandMoreIcon />}
          />
          <StyledBreadcrumb
            label={shipmentDetails?.order?.name}
            deleteIcon={<ExpandMoreIcon />}
          />
        </Breadcrumbs>
      </div>
      <Container maxWidth="xl">
        <Grid container mt={2} className="width100">
          <Tabs
            className="width100"
            centered
            value={value}
            onChange={handleChange}
          >
            <Tab label="Overview" className="TabChangesDevice" />
            <Tab label="Analytics" className="TabChangesDevice" />
            <Tab label="Location" className="TabChangesDevice" />
            <Tab label="Alerts" className="TabChangesDevice" />
            <Tab label="Sensors" className="TabChangesDevice" />
            <Tab label="logs" className="TabChangesDevice" />
            {/* <Tab label="Map" className="TabChangesDevice" /> */}
          </Tabs>
          <TabPanel value={value} index={0} className="width100">
            <OverviewTab
              setValue={setValue}
              shipmentDetails={shipmentDetails}
              GetshipmentDetails={GetshipmentDetails}
            />
          </TabPanel>
          <TabPanel value={value} index={1} className="width100">
            <AnalyticsTab shipmentDetails={shipmentDetails} />
          </TabPanel>
          <TabPanel value={value} index={2} className="width100">
            <LocationTab shipmentDetails={shipmentDetails} />
          </TabPanel>
          <TabPanel value={value} index={3} className="width100">
            <AlertsTab />
          </TabPanel>
          <TabPanel value={value} index={4} className="width100">
            <SensorsTab shipmentDetails={shipmentDetails} />
          </TabPanel>
          <TabPanel value={value} index={5} className="width100">
            <LogsTab
              shipmentDetails={shipmentDetails}
              GetshipmentDetails={GetshipmentDetails}
            />
          </TabPanel>
          {/* <TabPanel value={value} index={6} className="width100">
            <MapTab
              setValue={setValue}
              shipmentDetails={shipmentDetails}
              GetshipmentDetails={GetshipmentDetails}
            />
          </TabPanel> */}
        </Grid>
      </Container>
    </>
  );
}
