import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DownloadReport from "../../Action/DownloadAnalyticsDialog";
import SpeedLimit from "../AnalyticsTab/SpeedLimitDialog";

// **
import { axiosInstance } from "../../../../component/axios/axiosInstance";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children} </div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Analytics({ shipmentDetails }) {
  const deliveredTime = dayjs(shipmentDetails?.order?.deliveredTime).toDate();
  const CurrentDate = dayjs();
  const createdDate = dayjs(shipmentDetails?.order?.createdAt).toDate();
  const currentDate = deliveredTime ? deliveredTime : CurrentDate.toDate();
  const [popupIndex, setPopupIndex] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    setPopupIndex(null); // Reset popupIndex when closing the popup
  };
  const { id } = useParams();
  const [startDate, setStartDate] = useState();
  const [sensor, setSensor] = useState("temperature");
  const [sensorDetails, setSensorDetails] = useState(null);
  // ** ========================= Graph States===========================
  const [labels, setLabels] = React.useState([]);
  const [DataSets, setDataSets] = React.useState([]);
  const [SpeedReport, setSpeedReport] = useState(null);
  const data = sensorDetails?.sensor?.result;

  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          stepSize: 1, // Set the step size between ticks
        },
        scrollbar: {
          enabled: true, // Enable the scrollbar
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
      // tooltip: {
      //   enabled: true,
      //   custom: function (tooltipModel) {
      //     console.log("Custom tooltip function called");
      //     console.log("Sensor value:", sensor);
      //     if (sensor === "speed") {
      //       // Tooltip content
      //       let tooltipText = "View Speed";
      //       const tooltipElement = document.getElementById("custom-tooltip");
      //       tooltipElement.innerHTML = tooltipText;
      //       tooltipElement.style.display = "block";
      //       tooltipElement.style.position = "absolute";
      //       tooltipElement.style.left = tooltipModel.caretX + "px";
      //       tooltipElement.style.top = tooltipModel.caretY + "px";
      //       tooltipElement.style.backgroundColor = "rgba(255, 255, 255, 0.8)";
      //       tooltipElement.style.padding = "5px";
      //       tooltipElement.style.border = "1px solid #ccc";
      //     } else {
      //       console.log("Hiding tooltip");
      //       document.getElementById("custom-tooltip").style.display = "none";
      //     }
      //   },
      // },
    },

    onClick: (event, elements) => {
      const clickedElement = elements[0];
      if (clickedElement && sensor === "speed") {
        const dataIndex = clickedElement.index;
        const clickedData = data[dataIndex];
        setSpeedReport(clickedData);
        setPopupIndex(elements);
        handlePopupOpen();
      }
    },
  };
  const CustomTooltip = () => <div id="custom-tooltip"></div>;

  const [value, setValue] = React.useState(null);
  useEffect(() => {
    setValue(sensor === "vibrationDrop" ? 0 : null);
  }, [sensor]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const SensorTypeChange = (newValue) => {
    setSensor(newValue);
  };
  const handleData = (data, datatype) => {
    if (datatype == "startDate") {
      setStartDate(data);
    }
  };
  useEffect(() => {
    if (startDate) {
      setStartDate(dayjs(startDate).startOf("day"));
    } else if (currentDate) {
      setStartDate(dayjs(currentDate).startOf("day"));
    }
  }, []);
  const apiUrl = `/api/sensor/fetchSensorData/`;
  const params = {
    search: {},
    filter: { order_ref: id, isCharging: { $ne: true } },
    page: 1,
    limit: 10000,
    select: {
      createdAt: 1,
      currentValue: 1,
      _id: 0,
      angleRoll: 1,
      anglePitch: 1,
      ampX: 1,
      ampY: 1,
      ampZ: 1,
      vrmsX: 1,
      vrmsY: 1,
      vrmsZ: 1,
      "rawData.lat_1": 1,
      "rawData.long_1": 1,
      "rawData.lat_2": 1,
      "rawData.long_2": 1,
      "rawData.origin": 1,
      "rawData.destinationLocation": 1,
      "rawData.originTime": 1,
      "rawData.destinationTime": 1,
      "rawData.distance": 1,
    },
    startDate: new Date(dayjs(startDate ?? currentDate).startOf("day")),
    endDate: new Date(dayjs(startDate ?? currentDate).endOf("day")),
    sensorName:
      sensor === "vibrationDrop"
        ? "vibration"
        : value === 1
        ? "vibration1"
        : sensor,
    sort: { createdAt: 1 },
  };

  async function GetSensorDetails() {
    try {
      const response = await axiosInstance.get(apiUrl, { params });
      setSensorDetails(response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  }
  useEffect(() => {
    if (id && sensor) {
      GetSensorDetails();
    } else if (startDate && sensor && id) {
      GetSensorDetails();
    }
  }, [id, sensor, startDate]);
  useEffect(() => {
    if (sensorDetails?.sensor?.result?.length > 0 && startDate) {
      setLabels(data?.map((item) => dayjs(item?.createdAt).format("h:mm A")));
    }
  }, [data]);

  useEffect(() => {
    if (sensorDetails?.sensor?.result?.length > 0 && startDate) {
      if (sensor == "temperature") {
        let arr = [];
        for (let i = 0; i < new Array(data).length; i++) {
          let obj = {};
          obj["label"] = `temperature`;
          obj["data"] = data?.map((item) => item?.currentValue);
          obj["borderColor"] = "rgba(255, 99, 132, 0.5)";
          obj["backgroundColor"] = "rgb(255, 99, 132)";
          arr.push(obj);
        }
        setDataSets(arr);
      } else if (sensor === "humidity") {
        let arr = [];
        for (let i = 0; i < new Array(data).length; i++) {
          let obj = {};
          obj["label"] = `humidity`;
          obj["data"] = data?.map((item) => item?.currentValue);
          obj["borderColor"] = "rgba(230, 230, 0, 0.5)";
          obj["backgroundColor"] = "rgb(230, 230, 0)";
          arr.push(obj);
        }
        setDataSets(arr);
      } else if (sensor === "g-force") {
        let arr = [];
        for (let i = 0; i < new Array(data).length; i++) {
          let obj = {};
          obj["label"] = `g-force`;
          obj["data"] = data?.map((item) => item?.currentValue);
          obj["borderColor"] = "rgba(51, 204, 255, 0.5)";
          obj["backgroundColor"] = "rgb(51, 204, 255)";
          arr.push(obj);
        }
        setDataSets(arr);
      } else if (sensor === "battery") {
        let arr = [];
        for (let i = 0; i < new Array(data).length; i++) {
          let obj = {};

          obj["label"] = `battery`;
          obj["data"] = data?.map(
            (item) => Math.ceil(Number(item?.currentValue) / 5) * 5
          );
          obj["borderColor"] = "rgba(238, 130, 238, 0.5)";
          obj["backgroundColor"] = "rgb(204, 51, 255)";
          arr.push(obj);
        }
        setDataSets(arr);
      } else if (sensor === "gps") {
        let arr = [];
        for (let i = 0; i < new Array(data).length; i++) {
          let obj = {};
          obj["label"] = `gps`;
          obj["data"] = data?.map((item) => item?.currentValue);
          obj["borderColor"] = "rgba(60, 179, 113, 0.5)";
          obj["backgroundColor"] = "rgb(60, 179, 113)";
          arr.push(obj);
        }
        setDataSets(arr);
      } else if (sensor === "orientation") {
        let arr = [];
        for (let i = 0; i < new Array(data).length; i++) {
          let obj = {};
          let obj2 = {};
          obj["label"] = `anglePitch`;
          obj["data"] = data?.map((item) => item?.anglePitch);
          obj["borderColor"] = "rgba(255, 165, 0, 0.5)";
          obj["backgroundColor"] = "rgb(255, 165, 0)";
          obj2["label"] = `angleRoll`;
          obj2["data"] = data?.map((item) => item?.angleRoll);
          obj2["borderColor"] = "rgba(0, 0, 255, 0.5)";
          obj2["backgroundColor"] = "rgba(0, 0, 255)";
          arr.push(obj, obj2);
        }
        setDataSets(arr);
      } else if (sensor === "speed") {
        let arr = [];
        for (let i = 0; i < new Array(data).length; i++) {
          let obj = {};
          obj["label"] = `speed`;
          obj["data"] = data?.map((item) => item?.currentValue);
          obj["borderColor"] = "rgba(155, 19, 132, 0.5)";
          obj["backgroundColor"] = "rgb(155, 19, 132)";
          arr.push(obj);
        }
        setDataSets(arr);
      } else if (value === 0) {
        let arr = [];
        for (let i = 0; i < new Array(data).length; i++) {
          let obj = {};
          let obj2 = {};
          let obj3 = {};
          obj["label"] = `X`;
          obj["data"] = data?.map((item) => item?.ampX);
          obj["borderColor"] = "rgba(255, 165, 0, 0.5)";
          obj["backgroundColor"] = "rgb(255, 165, 0)";

          obj2["label"] = `Y`;
          obj2["data"] = data?.map((item) => item?.ampY);
          obj2["borderColor"] = "rgba(0, 0, 255, 0.5)";
          obj2["backgroundColor"] = "rgba(0, 0, 255)";

          obj3["label"] = `Z`;
          obj3["data"] = data?.map((item) => item?.ampZ);
          obj3["borderColor"] = "rgba(238, 130, 238, 0.5)";
          obj3["backgroundColor"] = "rgb(204, 51, 255)";

          arr.push(obj, obj2, obj3);
        }

        setDataSets(arr);
      } else if (value === 1) {
        let arr = [];
        for (let i = 0; i < new Array(data).length; i++) {
          let obj = {};
          let obj2 = {};
          let obj3 = {};
          obj["label"] = `X`;
          obj["data"] = data?.map((item) => item?.vrmsX);
          obj["borderColor"] = "rgba(255, 99, 132, 0.5)";
          obj["backgroundColor"] = "rgb(255, 99, 132)";

          obj2["label"] = `Y`;
          obj2["data"] = data?.map((item) => item?.vrmsY);
          obj2["borderColor"] = "rgba(230, 230, 0, 0.5)";
          obj2["backgroundColor"] = "rgb(230, 230, 0)";

          obj3["label"] = `Z`;
          obj3["data"] = data?.map((item) => item?.vrmsZ);
          obj3["borderColor"] = "rgba(51, 204, 255, 0.5)";
          obj3["backgroundColor"] = "rgb(51, 204, 255)";
          arr.push(obj, obj2, obj3);
        }

        setDataSets(arr);
      }
    } else {
      setDataSets([]);
    }
  }, [startDate, sensorDetails, sensor, value]);
  //**  startDate, currentDate
  return (
    <>
      <Grid container justifyContent={"space-between"}>
        <Typography variant="h6">Manage Shipment Analytics</Typography>
        <DownloadReport
          profileData={shipmentDetails}
          sensor={sensor}
          startDate={startDate}
          currentDate={currentDate}
          value={value}
        />
        
      </Grid>
      <Grid
        container
        justifyContent={"space-between"}
        mt={2}
        sx={{
          backgroundColor: DataSets?.length > 0 ? "#f7f8fd" : "",
          border: DataSets?.length > 0 ? "1px solid #ddd" : "",
          padding: "10px",
          borderRadius: "8px",
        }}
      >
        <Grid item>
          <Typography variant="subtitle1" className="tablecell-bg">
            {" "}
            Select Sensor Type
          </Typography>

          <TextField
            select
            variant="filled"
            className="Selectdropstyle"
            sx={{ minWidth: "250px" }}
            InputProps={{ disableUnderline: true }}
            labelId="demo-select-small"
            id="demo-select-small"
            defaultValue={"temperature"}
            inputProps={{ "aria-label": "Without label" }}
            onChange={(e) => {
              SensorTypeChange(e.target.value);
            }}
          >
            <MenuItem value={"temperature"} className="Selectmenustyle">
              Temperature
            </MenuItem>
            <MenuItem value={"humidity"} className="Selectmenustyle">
              Humidity
            </MenuItem>
            <MenuItem value={"g-force"} className="Selectmenustyle">
              G-Force
            </MenuItem>
            <MenuItem value={"battery"} className="Selectmenustyle">
              Battery
            </MenuItem>{" "}
            <MenuItem value={"orientation"} className="Selectmenustyle">
              Orientation
            </MenuItem>
            <MenuItem value={"speed"} className="Selectmenustyle">
              Speed
            </MenuItem>
            <MenuItem value={"vibrationDrop"} className="Selectmenustyle">
              Vibration
            </MenuItem>
            {/* <MenuItem value={"vibration"} className="Selectmenustyle">
              Amplitude
            </MenuItem>
            <MenuItem value={"vibration1"} className="Selectmenustyle">
              Displacement
            </MenuItem> */}
          </TextField>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" className="tablecell-bg">
            Select Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className="rangepicker width-150"
              //   disableFuture
              InputProps={{
                disableUnderline: true,
              }}
              inputFormat="dd/MM/yyyy"
              value={startDate ?? currentDate}
              onChange={(e) => {
                handleData(e, "startDate");
              }}
              minDate={createdDate}
              maxDate={currentDate}
              renderInput={(params) => (
                <TextField
                  sx={{ minWidth: "250px" }}
                  variant="filled"
                  className="rangepicker"
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "Start date",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        {sensor === "vibrationDrop" ? (
          <Grid container>
            <Tabs
              className="width100"
              centered
              value={value}
              onChange={handleChange}
            >
              <Tab label="Amplitude" className="TabChangesDevice" />
              <Tab label="Displacement" className="TabChangesDevice" />/
            </Tabs>
            <TabPanel value={value} index={0} className="width100">
              {DataSets && DataSets?.length > 0 ? (
                <Line
                  options={options}
                  data={{
                    labels,
                    datasets: DataSets,
                  }}
                />
              ) : (
                <Grid
                  container
                  justifyContent={"center"}
                  alignContent={"center"}
                  sx={{ height: "40vh" }}
                >
                  <Typography>No Data Found</Typography>
                </Grid>
              )}{" "}
            </TabPanel>

            <TabPanel value={value} index={1} className="width100">
              {DataSets && DataSets?.length > 0 ? (
                <Line
                  options={options}
                  data={{
                    labels,
                    datasets: DataSets,
                  }}
                />
              ) : (
                <Grid
                  container
                  justifyContent={"center"}
                  alignContent={"center"}
                  sx={{ height: "40vh" }}
                >
                  <Typography>No Data Found</Typography>
                </Grid>
              )}{" "}
            </TabPanel>
          </Grid>
        ) : (
          <Grid container>
            {DataSets && DataSets?.length > 0 ? (
              <>
                <div
                  style={{ width: "100%" }}
                  className={`chart-container ${
                    sensor === "speed" ? "cursor-pointer" : ""
                  }`}
                >
                  <Line
                    options={options}
                    updateMode="active"
                    data={{
                      labels,
                      datasets: DataSets,
                    }}
                  />
                  {sensor === "speed" && <CustomTooltip />}
                </div>

                {popupIndex !== null && (
                  <SpeedLimit
                    open={isPopupOpen}
                    setOpen={handlePopupClose}
                    SpeedReport={SpeedReport}
                  />
                )}
              </>
            ) : (
              <Grid
                container
                justifyContent={"center"}
                alignContent={"center"}
                sx={{ height: "40vh" }}
              >
                <Typography>No Data Found</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}
