import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Chip,
  Typography,
  Breadcrumbs,
  Container,
  Table,
  TableSortLabel,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import { emphasize, styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { axiosInstance } from "../../component/axios/axiosInstance";
import CustomTooltip from "../../component/CustomTooltip/CustomTooltip";
import { AiOutlineEye } from "react-icons/ai";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});
export default function Testing() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [deviceDetails, setDeviceDetails] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleRequestSort = (event, key) => {
    const isAsc = orderBy === key && order === "asc";
    const newOrder = orderBy === key ? (isAsc ? "desc" : "asc") : "desc";
    setOrder(newOrder);
    setOrderBy(key);
  };
  const SortValue = order === "asc" ? 1 : -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const apiUrl = `/api/device/fetchSingleDevice/${id}`;

  async function GetDeviceDetails() {
    try {
      const response = await axiosInstance.get(apiUrl);
      setDeviceDetails(response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  useEffect(() => {
    GetDeviceDetails();
  }, [id]);
  return (
    <>
      <div role="presentation" style={{ marginTop: "100px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            label="Device Management"
            onClick={() => navigate("/device-management")}
            deleteIcon={<ExpandMoreIcon />}
          />
          <StyledBreadcrumb
            label={deviceDetails?.device?.name}
            deleteIcon={<ExpandMoreIcon />}
          />
        </Breadcrumbs>
      </div>
      <Container maxWidth="xl">
        <Grid
          container
          justifyContent="space-between"
          mt={4}
          sx={{
            backgroundColor: "#f5f5f5",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          <Grid item>
            <Typography className="tablecell-bg">Device UID</Typography>
            <Typography className="tablecrow-cell-bg mt-10px">
              {deviceDetails?.device?.deviceUid}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="tablecell-bg">Device Name</Typography>
            <Typography className="tablecrow-cell-bg mt-10px">
              {deviceDetails?.device?.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="tablecell-bg">MAC ID</Typography>
            <Typography className="tablecrow-cell-bg mt-10px">
              {deviceDetails?.device?.macId}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="tablecell-bg">Status</Typography>
            {deviceDetails?.device?.status === 1 ? (
              <Typography className="status-ongoing  mt-10px">
                Assigned
              </Typography>
            ) : (
              <Typography className="status-n-a  mt-10px">
                Not Assigned
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography className="tablecell-bg">Added on</Typography>
            <Typography className="tablecrow-cell-bg mt-10px">
              {deviceDetails?.device?.createdAt
                ? `
            ${dayjs(deviceDetails?.device?.createdAt).format("h:mm A")},
            ${dayjs(deviceDetails?.device?.createdAt).format("DD-MM-YYYY")}`
                : ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={3} mb={3}>
          <Typography variant="h6"> History</Typography>
        </Grid>
        <TableContainer className="mt-20px ">
          <Table
            stickyHeader
            aria-label="sticky table"
            className="mt-20"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell className="tablecell-bg">Shipment Name</TableCell>
                <TableCell className="tablecell-bg">Status</TableCell>
                <TableCell className="tablecell-bg">Origin</TableCell>
                <TableCell className="tablecell-bg">Current Location</TableCell>
                <TableCell className="tablecell-bg">Destination</TableCell>
                <TableCell className="tablecell-bg">Alerts</TableCell>
                <TableCell className="tablecell-bg">Last Updated</TableCell>
                <TableCell className="tablecell-bg">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deviceDetails?.device?.orders?.map((item, index) => {
                return (
                  <TableRow key={index} hover className="clickable-row">
                    <TableCell className="tablecrow-cell-bg ">
                      {item?.order_id}
                      <br />
                      <span className="tablecell-bg"> {item?.name}</span>
                    </TableCell>
                    <TableCell className="">
                      {item?.status === 1 ? (
                        <Typography className="status-ongoing">
                          Ongoing
                        </Typography>
                      ) : (
                        <Typography className="status-complete">
                          Complete
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell className="tablecrow-cell-bg ">
                      {item?.origin}
                      <br />
                      <span className="tablecell-bg">
                        {item?.originLocation?.coordinates[0]},
                        {item?.originLocation?.coordinates[1] ?? "--"}
                      </span>
                    </TableCell>
                    <TableCell className="tablecrow-cell-bg ">
                      {item?.currentLocation?.name ?? "--"}
                      <br />
                      <span className="tablecell-bg">
                        {item?.currentLocation?.coordinates[0] ?? ""}{" "}
                        {item?.currentLocation?.coordinates[0] ? "," : ""}
                        {item?.currentLocation?.coordinates[1] ?? ""}
                      </span>
                    </TableCell>

                    <TableCell className="tablecrow-cell-bg ">
                      {item?.destination}
                      <br />
                      <span className="tablecell-bg">
                        {item?.destinationLocation?.coordinates[0]},
                        {item?.destinationLocation?.coordinates[1]}
                      </span>
                    </TableCell>

                    <TableCell
                      className={
                        item?.alerts < 1 ? "tablecrow-cell-bg" : "error"
                      }
                    >
                      {item?.alerts}
                    </TableCell>
                    <TableCell className="tablecrow-cell-bg ">
                      {item?.updatedAt
                        ? dayjs(item?.updatedAt).format("h:mm A")
                        : dayjs(item?.createdAt).format("h:mm A")}

                      <br />
                      <span className="tablecell-bg">
                        {item?.updatedAt
                          ? dayjs(item?.updatedAt).format("DD-MM-YYYY")
                          : dayjs(item?.createdAt).format("DD-MM-YYYY")}
                      </span>
                    </TableCell>
                    <TableCell className="tablecrow-cell-bg ">
                      <Grid container justifyContent={"space-between"}>
                        <Grid item>
                          <CustomTooltip title={"View Profile"}>
                            <IconButton
                              onClick={() =>
                                navigate(`/shipment-details/${item._id}`)
                              }
                            >
                              <AiOutlineEye />
                            </IconButton>
                          </CustomTooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {deviceDetails?.device?.orders?.length < 1 && (
          <Grid
            container
            justifyContent={"center"}
            alignContent={"center"}
            sx={{ height: "40vh" }}
          >
            <Typography>No Data Found</Typography>
          </Grid>
        )}
        <div style={{ marginBottom: "50px" }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={deviceDetails?.orders?.totalCount ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Container>
    </>
  );
}
