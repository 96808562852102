import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import {
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineEye } from "react-icons/ai";
import { BsBatteryCharging } from "react-icons/bs";
import { axiosInstance } from "../../../component/axios/axiosInstance";
import SensorProfile from "../ShipmentDetailTab/Sensor-details";
import CustomTooltip from "../../../component/CustomTooltip/CustomTooltip";
export default function SensorTab({ shipmentDetails }) {
  const { id } = useParams();

  const [sensorDetails, setSensorDetails] = useState(null);

  const apiUrl = `/api/sensor/fetchAllSensor/${id}`;

  async function GetSensorDetails() {
    try {
      const response = await axiosInstance.get(apiUrl);
      setSensorDetails(response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  useEffect(() => {
    if (id) {
      GetSensorDetails();
    }
  }, [id]);

  const [openSensorDetail, setOpenSensorDetail] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const SensorProflileOpen = (e, data) => {
    setOpenSensorDetail(false);
    setProfileData(data);
  };
  const SensorProflileClose = () => {
    setOpenSensorDetail(true);
  };
  return (
    <>
      {openSensorDetail ? (
        <>
          <Grid container justifyContent="space-between">
            <Typography variant="h6">Manage Sensor</Typography>
            <Typography variant="subtitle2" pt={2}>
              Showing {sensorDetails?.sensors?.length} Sensor
            </Typography>
          </Grid>
          <TableContainer className="mt-20px table-container">
            <Table
              stickyHeader
              aria-label="sticky table"
              className="mt-20"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className="tablecell-bg"
                    sx={{ padding: "14px 16px" }}
                  >
                    Sensor Name
                  </TableCell>
                  <TableCell className="tablecell-bg">Current value </TableCell>
                  <TableCell className="tablecell-bg">
                    Threshold Value
                  </TableCell>
                  <TableCell className="tablecell-bg">Last Updated </TableCell>
                  <TableCell className="tablecell-bg">Action </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sensorDetails?.sensors?.map((data, index) => {
                  const unitMappings = {
                    "Battery Status": "%",
                    "G-Force Sensor": "G",
                    "Temperature Sensor": "°C",
                    "Humidity Sensor": "%",
                    "Vibration Sensor": "m/s2",
                  };
                  const unit = unitMappings[data?.name] || "";
                  return (
                    <>
                      <TableRow hover className="clickable-row" key={index}>
                        <TableCell className="tablecrow-bg">
                          {data?.name}
                        </TableCell>

                        <TableCell className="tablecrow-bg ">
                          {data?.isCharging == true ? (
                            <BsBatteryCharging
                              style={{ transition: "" }}
                              fontSize={30}
                            />
                          ) : (
                            <span
                              className={
                                data?.currentValue === "Weak Signal"
                                  ? "tablecrow-cell-bg-orange"
                                  : data?.isAlert
                                  ? "tablecrow-cell-bg-red"
                                  : "tablecrow-cell-bg-green"
                              }
                            >
                              {data?.currentValue} {unit}
                            </span>
                          )}
                        </TableCell>
                        <TableCell className="tablecrow-bg ">
                          {data?.threshold == 0
                            ? "--"
                            : `${data?.threshold ?? "--"} ${unit ?? "--"}`}
                        </TableCell>
                        <TableCell className="tablecrow-cell-bg ">
                          {dayjs(data?.createdAt).format("h:mm A")},
                          <br />
                          <span className="tablecell-bg">
                            {dayjs(data?.createdAt).format("DD-MM-YYYY")}
                          </span>
                        </TableCell>
                        <TableCell className="tablecrow-cell-bg ">
                          <CustomTooltip title={"View Profile"}>
                            <IconButton
                              onClick={(e) => SensorProflileOpen(e, data)}
                            >
                              <AiOutlineEye />
                            </IconButton>
                          </CustomTooltip>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {sensorDetails?.sensors?.length < 1 && (
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              sx={{ height: "40vh" }}
            >
              <Typography>No Data Found</Typography>
            </Grid>
          )}
        </>
      ) : (
        <>
          <Button
            size="small"
            variant="contained"
            style={{
              color: "#fff",
              backgroundColor: "#5932EA",
              border: `1px solid ${"#ddd"}`,
              "&:hover": {
                backgroundColor: "#fff",
                color: "white",
              },
              marginBottom: "10px",
            }}
            onClick={SensorProflileClose}
            startIcon={<IoIosArrowBack />}
          >
            Back
          </Button>

          <SensorProfile
            profileData={profileData}
            shipmentDetails={shipmentDetails}
          />
        </>
      )}
    </>
  );
}
