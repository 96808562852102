import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Grid,
  IconButton,
  Avatar,
  Divider,
  Box,
  Badge,
  AppBar,
  Container,
  Button,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";

// core component
import { AuthContext } from "../Context/AuthContext";
import ShipmentLogo from "../assets/img/ShipmentLogo.png";
import { DiHtml5DeviceAccess } from "react-icons/di";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import NotificationDropdown from "../component/NotificationDropDown";
import { axiosInstance } from "../component/axios/axiosInstance";
const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));
const Dashboard = (props) => {
  const isMobile = useMediaQuery("(max-width:600px)"); // Define your mobile breakpoint here
  const { id } = useParams();

  const navigate = useNavigate();
  const routes = [
    {
      invisible: false,
      path: "/shipment-management",
      name: "Shipment Management",
      icon: <ShareLocationIcon />,
    },
    {
      invisible: false,
      path: "/device-management",
      name: "Device Management",
      icon: <DiHtml5DeviceAccess />,
    },
  ];
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [value, setValue] = React.useState(0);

  const { setToken } = useContext(AuthContext);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem("userData");
    navigate("/auth/signIn");
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const url = window.location.pathname;
  const [clickedItemIndex, setClickedItemIndex] = useState(0);
  const apiUrl = `/api/alert/fetchnotification`;

  const params = {
    search: "",
    filter: {},
    page: 1,
    limit: 10,
    select: { name: 1, order_ref: 1, createdAt: 1, sensor: 1 },
    startDate: "",
    endDate: "",
    sort: {},
  };
  async function GetAlertDetails() {
    try {
      const response = await axiosInstance.get(apiUrl, { params });
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  useEffect(() => {
    GetAlertDetails();
  }, []);

  return (
    <>
      <AppBar
        style={{
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            marginLeft: isMobile ? "60px" : "0px", // Define your mobile margin here
          }}
        >
          <Container maxWidth="xl">
            <Grid
              container
              justifyContent={"space-between"}
              alignItems="center"
              mt={1}
              mb={1}
            >
              <Grid item>
                <Grid container item>
                  <Link to="/shipment-management" className="linkcolor">
                    <img
                      src={ShipmentLogo}
                      alt="Shipment"
                      style={{
                        width: "4.5em",
                        borderRadius: "100px",
                        marginTop: "6px",
                      }}
                    />
                  </Link>{" "}
                  <Grid item>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: { xs: "none", sm: "flex", md: "flex" },
                      }}
                    >
                      {routes.map((route, index) => {
                        const isActive = index === clickedItemIndex;

                        if (route.invisible === false) {
                          return (
                            <ListItem
                              button
                              component={Link}
                              to={route.path}
                              sx={{
                                backgroundColor: isActive
                                  ? "#D5CBF9"
                                  : "transparent",
                                borderRadius: "8px",
                                marginLeft: "15px",
                                width: "auto",
                                padding: "5px 12px",
                                fontSize: "14px",
                                marginTop: "8px",
                              }}
                              onClick={() => {
                                setValue(route.activeIndex);
                                setClickedItemIndex(index); // Update the clicked item index
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: "20px",
                                  paddingRight: "6px",
                                  fontSize: "26px",
                                }}
                                className={
                                  isActive ? "primary-color" : "disable-color"
                                }
                              >
                                {route.icon}
                              </ListItemIcon>
                              <ListItemText
                                disableTypography
                                className={
                                  isActive ? "primary-color" : "disable-color"
                                }
                              >
                                {route.name}
                              </ListItemText>
                            </ListItem>
                          );
                        }
                      })}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <NotificationDropdown />

                <Tooltip title="Open settings">
                  <IconButton
                    className="notificationdiv"
                    onClick={handleOpenUserMenu}
                  >
                    <Badge
                      overlap="circular"
                      sx={{ cursor: "pointer" }}
                      badgeContent={<BadgeContentSpan />}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    >
                      <Avatar
                        alt="Shipment"
                        sx={{ width: 50, height: 50, fontSize: "25px" }}
                        src="/images/avatars/1.png"
                      />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  keepMounted
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Box sx={{ pt: 2, pb: 3, px: 4 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Badge
                        overlap="circular"
                        badgeContent={<BadgeContentSpan />}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        <Avatar
                          alt="Shipment"
                          src="/images/avatars/1.png"
                          sx={{ width: 50, height: 50, fontSize: "25px" }}
                        />
                      </Badge>
                      <Box
                        sx={{
                          display: "flex",
                          marginLeft: 3,
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600 }}>
                          Shipment Tracking
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "0.8rem", color: "text.disabled" }}
                        >
                          Admin
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />

                  <MenuItem onClick={logout}>
                    <Box sx={{ pb: 0, px: 2.5 }}>
                      <ListItemIcon sx={{ pt: 1 }}>
                        <IoIosLogOut fontSize={22} />
                      </ListItemIcon>
                    </Box>

                    <Typography sx={{ fontWeight: 600 }} textAlign="center">
                      Logout
                    </Typography>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Container>{" "}
        </div>
      </AppBar>
    </>
  );
};

export default Dashboard;
