import React, { ChangeEvent, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {
  DialogTitle,
  Typography,
  Button,
  TextField,
  Grid,
  DialogActions,
  DialogContent,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import { FiSend } from "react-icons/fi";

import { useForm } from "react-hook-form";
import { BsPlusCircle } from "react-icons/bs";

import CancelAlertDialog from "./CancelAlertDialog";
import { axiosInstance } from "../../../component/axios/axiosInstance";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AddShipment({ fetchData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const registerOptions = { required: true };

  const [respDevice, setDevice] = useState("");
  const deviceName = respDevice?.devices?.result;

  const [formStateData, setFormState] = useState({
    shipmentUID: "",
    originName: "",
    deviceUid: "",
    shipmentName: "",
    originLattitude: "",
    destination: "",
    destinationlattitude: "",
  });
  const [deviceFormValues, setDeviceFormValues] = useState({
    deviceName: null,
  });
  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("sm");
  // ===================== SnackBar ==================== //
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const vertical = "top";
  const horizontal = "center";
  const apiUrl = "/api/device/fetchDevices";
  const params = {
    search: "",
    filter: { status: 0 },
    page: 1,
    limit: 100,
    select: { name: 1, macId: 1, _id: 0 },
    startDate: "",
    endDate: "",
    sort: { name: -1 },
  };

  async function fetchDataDevice() {
    try {
      const response = await axiosInstance.get(apiUrl, { params });
      setDevice(response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  }
  useEffect(() => {
    if (open) {
      fetchDataDevice();
    }
  }, [open]);
  const openSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleAutocompleteChange = (event, value, field) => {
    setDeviceFormValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleClearDeviceName = () => {
    setDeviceFormValues((prevState) => ({
      ...prevState,
      deviceName: null,
    }));
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormState((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
    setFormState((prevState) => ({
      ...prevState,
      shipmentUID: "",
      originName: "",
      shipmentName: "",
      originLattitude: "",
      destination: "",
      destinationlattitude: "",
    }));
  };
  const handleAddShipment = async () => {
    let body = {
      name: formStateData.shipmentName,
      order_id: formStateData.shipmentUID,
      device_id: deviceFormValues?.deviceName?.macId,
      origin: formStateData.originName,
      originLatLng: formStateData.originLattitude,
      destination: formStateData.destination,
      destinationLatLng: formStateData.destinationlattitude,
    };
    try {
      const resp = await axiosInstance.post("/api/order/addOrder", body);
      openSnackbar(resp?.data?.msg, "success");
      fetchData();
      handleClose();
    } catch (error) {
      openSnackbar(error?.response?.data?.msg || "An error occurred.", "error");
    }
  };

  return (
    <>
      <Snackbar
        sx={{ marginTop: "60px" }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={closeSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Button
        variant="contained"
        size="medium"
        onClick={handleClickOpen}
        style={{
          color: "#fff",
          backgroundColor: "#5932EA",
          border: `1px solid ${"#ddd"}`,
          "&:hover": {
            backgroundColor: "#fff",
            color: "white",
          },
        }}
        startIcon={<BsPlusCircle />}
      >
        Create New Shipment
      </Button>
      <BootstrapDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            padding: "6px 20px",
            color: "white",
            backgroundColor: "#5932EA",
            m: 0,
            p: 2,
          }}
        >
          <Typography variant="h6">New Shipment Order</Typography>
          <Typography variant="subtitle1">
            Enter Basic Information to add Shipment
          </Typography>
        </DialogTitle>

        <form onSubmit={handleSubmit(handleAddShipment)}>
          <DialogContent dividers>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="shipmentUID"
                  label="Shipment UID"
                  variant="outlined"
                  size="small"
                  className=" mt-20px "
                  {...register("shipmentUID", registerOptions)}
                  name="shipmentUID"
                  error={!formStateData.shipmentUID && !!errors.shipmentUID}
                  helperText={
                    !formStateData.shipmentUID
                      ? errors.shipmentUID && "Shipment UID  is required"
                      : ""
                  }
                  value={formStateData.shipmentUID}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="shipmentName"
                  label="Shipment Name"
                  variant="outlined"
                  size="small"
                  className="mt-20px"
                  {...register("shipmentName", registerOptions)}
                  name="shipmentName"
                  error={!formStateData.shipmentName && !!errors.shipmentName}
                  helperText={
                    !formStateData.shipmentName
                      ? errors.shipmentName && "Shipment Name is required"
                      : ""
                  }
                  value={formStateData.shipmentName}
                  onChange={handleInputChange}
                />{" "}
              </Grid>
            </Grid>{" "}
            <Grid container>
              <Grid item xs={12}>
                <Autocomplete
                  id="deviceName"
                  className=" mt-20px "
                  options={deviceName || []}
                  sx={{ color: "#000000", fontWeight: 700 }}
                  getOptionLabel={(option) => option?.name ?? ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Assign Available Device"
                      error={
                        !deviceFormValues.deviceName && !!errors.deviceName
                      }
                      helperText={
                        !deviceFormValues.deviceName
                          ? errors.deviceName && "Device Name is required"
                          : ""
                      }
                      {...register("deviceName", registerOptions)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  clearIcon={<ClearIcon onClick={handleClearDeviceName} />}
                  value={deviceFormValues?.deviceName}
                  onChange={(event, value) =>
                    handleAutocompleteChange(event, value, "deviceName")
                  }
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option?.name ?? "", inputValue, {
                      insideWords: true,
                    });
                    const parts = parse(option?.name ?? "", matches);

                    return (
                      <li {...props}>
                        <div>
                          {parts?.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                color: "#000000",
                                fontWeight: part.highlight ? 700 : 300,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="originName"
                  label="Origin Name"
                  variant="outlined"
                  size="small"
                  className=" mt-20px "
                  {...register("originName", registerOptions)}
                  name="originName"
                  error={!formStateData.originName && !!errors.originName}
                  helperText={
                    !formStateData.originName
                      ? errors.originName && "Origin Name  is required"
                      : ""
                  }
                  value={formStateData.originName}
                  onChange={handleInputChange}
                />{" "}
                <TextField
                  fullWidth
                  id="destination"
                  label="Destination"
                  variant="outlined"
                  size="small"
                  className=" mt-20px"
                  {...register("destination", registerOptions)}
                  name="destination"
                  error={!formStateData.destination && !!errors.destination}
                  helperText={
                    !formStateData.destination
                      ? errors.destination && "Destination  is required"
                      : ""
                  }
                  value={formStateData.destination}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="originLattitude"
                  label="Origin Lattitude and Longitude"
                  variant="outlined"
                  size="small"
                  className=" mt-20px "
                  {...register("originLattitude", registerOptions)}
                  name="originLattitude"
                  error={
                    !formStateData.originLattitude && !!errors.originLattitude
                  }
                  helperText={
                    !formStateData.originLattitude
                      ? errors.originLattitude &&
                        "Origin Lattitude and Longitude  is required"
                      : ""
                  }
                  value={formStateData.originLattitude}
                  onChange={handleInputChange}
                />{" "}
                <TextField
                  fullWidth
                  id="destinationlattitude"
                  label="Destination lattitude and Longitude"
                  variant="outlined"
                  size="small"
                  className=" mt-20px "
                  type="text"
                  {...register("destinationlattitude", registerOptions)}
                  name="destinationlattitude"
                  error={
                    !formStateData.destinationlattitude &&
                    !!errors.destinationlattitude
                  }
                  helperText={
                    !formStateData.destinationlattitude
                      ? errors.destinationlattitude &&
                        "Destination lattitude and Longitude  is required"
                      : ""
                  }
                  value={formStateData.destinationlattitude}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>{" "}
            <DialogActions className="mt-20px">
              <div className="mr-10px">
                <CancelAlertDialog handleCloseFirst={handleClose} />
              </div>
              <Button
                variant="contained"
                type="submit"
                startIcon={<FiSend />}
                style={{
                  color: "#fff",
                  backgroundColor: "#5932EA",
                  border: `1px solid ${"#ddd"}`,
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "white",
                  },
                }}
                // disabled={divisionAddReponce.loading === true && true}
              >
                Submit
                {/* <CircularProgress className="loading-for-btn" size={24} /> */}
              </Button>
            </DialogActions>{" "}
          </DialogContent>{" "}
        </form>
      </BootstrapDialog>
    </>
  );
}
