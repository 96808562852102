import React, { ChangeEvent, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { BiExport } from "react-icons/bi";

import {
  DialogTitle,
  Typography,
  Button,
  TextField,
  Grid,
  DialogActions,
  IconButton,
  DialogContent,
  Table,
  TableSortLabel,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FiEye } from "react-icons/fi";
import { BsPlusCircle } from "react-icons/bs";
import { axiosInstance } from "../../../component/axios/axiosInstance";
import NodataFound from "../../../assets/img/nodatafound.png";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        color: "white",
        backgroundColor: "#5932EA",
        m: 0,
        p: "10px 15px 10px 10px",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          className="dialog-iconbtn"
          sx={{
            color: (theme) => theme.palette.common.white,
            backgroundColor: "transparent",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
export default function DownloadReport({
  sensor,
  currentDate,
  startDate,
  value,
}) {
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("lg");
  const [sensorDetails, setSensorDetails] = useState(null);

  // ===================== SnackBar ==================== //
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const vertical = "top";
  const horizontal = "center";
  const openSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };
  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const params = {
    search: {},
    filter: { order_ref: id },
    page: 1,
    limit: 10000,
    select: { name: 1, createdAt: 1, currentValue: 1, threshold: 1 },
    startDate: new Date(dayjs(startDate ?? currentDate).startOf("day")),
    endDate: new Date(dayjs(startDate ?? currentDate).endOf("day")),
    sensorName:
      sensor === "vibrationDrop" && value === 0
        ? "vibration"
        : value === 1
        ? "vibration1"
        : sensor,
    sort: { createdAt: -1 },
  };
  const apiUrl = `/api/sensor/fetchSensorData/`;
  const data = sensorDetails?.sensor?.result;

  async function GetSensorDetails() {
    try {
      const response = await axiosInstance.get(apiUrl, { params });
      setSensorDetails(response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  }
  const handleExport = () => {
    const modifiedData = data?.map((row) => {
      const unitMappings = {
        "Battery Sensor": "%",
        "Orientation Sensor": "°",
        "G-Force Sensor": "G",
        "Temperature Sensor": "°C",
        "Humidity Sensor": "%",
      };
      const unit = unitMappings[row?.name] || "";
      const Time = dayjs(row?.createdAt).format("h:mm A");
      const Date = dayjs(row?.createdAt).format("DD-MM-YYYY");
      const SensorName = row?.name;
      const ThresholdValue = `${row?.threshold ?? "--"} ${
        row?.threshold ? unit : ""
      }`;
      const Value = `${row?.currentValue} ${
        row?.currentValue === 0 ? "" : row?.currentValue && unit
      }`;
      return {
        Time,
        Date,
        SensorName,
        ThresholdValue,
        Value,
      };
    });

    const csvData = [];
    const tableHeading = "Manage Sensor Logs";
    csvData.push([[], [], tableHeading, [], []]);
    csvData.push([]);
    const headerRow = [
      "Time",
      "Date",
      "Sensor Name",
      "Threshold Value",
      "Value",
    ];
    csvData.push(headerRow);
    modifiedData.forEach((row) => {
      const rowData = [
        row.Time,
        row.Date,
        row.SensorName,
        row.ThresholdValue,
        row.Value,
      ];
      csvData.push(rowData);
    });

    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Sensor Report.csv");
    openSnackbar(" Report download successfull", "success");
    handleClose();
  };

  useEffect(() => {
    if (open || value || id) {
      GetSensorDetails();
    }
  }, [open, id, value]);

  return (
    <>
      <Snackbar
        sx={{ marginTop: "60px" }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={closeSnackbar} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Button
        variant="contained"
        size="medium"
        onClick={handleClickOpen}
        style={{
          color: "#fff",
          backgroundColor: "#5932EA",
          border: `1px solid ${"#ddd"}`,
          "&:hover": {
            backgroundColor: "#fff",
            color: "white",
          },
        }}
        startIcon={<FiEye />}
      >
        Preview Report
      </Button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography variant="h6" fontSize={18}>
            Download Analytics Report
          </Typography>
        </BootstrapDialogTitle>

        <Grid
          container
          justifyContent={"space-between"}
          sx={{ padding: "0px 16px", mt: 2 }}
        >
          <Grid item>
            <Typography variant="h6">Manage Logs</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              Showing {sensorDetails?.sensor?.result?.length} of{" "}
              {sensorDetails?.sensor?.totalCount} Logs
            </Typography>{" "}
          </Grid>
        </Grid>
        <TableContainer
          className="mt-20px table-container"
          style={{ width: "97%", margin: "auto" }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            className="mt-20"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className="tablecell-bg"
                  sx={{ padding: "14px 16px" }}
                >
                  Sensor Name
                </TableCell>
                <TableCell className="tablecell-bg">Value</TableCell>
                <TableCell className="tablecell-bg">Threshold</TableCell>
                <TableCell className="tablecell-bg">Date & Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sensorDetails?.sensor?.result?.map((data, index) => {
                const unitMappings = {
                  "Battery Sensor": "%",
                  "Orientation Sensor": "°",
                  "G-Force Sensor": "G",
                  "Temperature Sensor": "°C",
                  "Humidity Sensor": "%",
                };
                const unit = unitMappings[data?.name] || "";
                return (
                  <TableRow hover className="clickable-row" key={index}>
                    <TableCell className="tablecrow-bg">{data?.name}</TableCell>

                    <TableCell className="tablecrow-bg ">
                      {data?.currentValue ?? "--"}
                      {data?.currentValue == 0
                        ? ""
                        : data?.currentValue && unit}
                    </TableCell>
                    <TableCell className="tablecrow-bg ">
                      {data?.threshold ?? "--"}{" "}
                      {data?.threshold == 0 ? "" : data?.threshold && unit}
                    </TableCell>
                    <TableCell className="tablecrow-cell-bg ">
                      {dayjs(data?.createdAt).format("h:mm A")},
                      <br />
                      <span className="tablecell-bg">
                        {dayjs(data?.createdAt).format("DD-MM-YYYY")}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {sensorDetails?.sensor.totalCount == 0 && (
          <Grid
            container
            justifyContent={"center"}
            alignContent={"center"}
            sx={{ height: "50vh" }}
          >
            <Grid item>
              <img alt="NodataFound" src={NodataFound} />
              <Typography
                align="center"
                variant="h6"
                className="mt-16 blue-typo"
              >
                No Sensor Data Found!
              </Typography>
            </Grid>
          </Grid>
        )}
        <DialogActions sx={{ mr: 1.4, mt: 1 }}>
          <Button
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={handleClose}
            style={{
              color: "red",
              backgroundColor: "#fff",
              border: `1px solid ${"red"}`,
              "&:hover": {
                backgroundColor: "red" || "#fff",
                color: "white" || "red",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            startIcon={<BiExport />}
            variant="contained"
            size="medium"
            style={{
              color: "#fff",
              padding: "7px 13px",
              backgroundColor: "#5932EA",
              border: `1px solid ${"#ddd"}`,
              "&:hover": {
                backgroundColor: "#fff",
                color: "white",
              },
            }}
            onClick={handleExport}
          >
            Export
          </Button>{" "}
        </DialogActions>
      </Dialog>
    </>
  );
}
