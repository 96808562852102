import React, { useEffect } from "react";
import axiosInstance from "../axios/axiosInstance";

function useNotification(params, apiUrl) {
  const [alertDetails, setAlertDetails] = React.useState("");

  async function GetAlertDetails() {
    try {
      const response = await axiosInstance.get(apiUrl, { params });
      setAlertDetails(response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  }
  useEffect(() => {
    GetAlertDetails();
  }, []);
  return { alertDetails, GetAlertDetails };
}

export default useNotification;
