import React from "react";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles"; // Import tooltipClasses
import Zoom from "@mui/material/Zoom";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    describeChild
    {...props}
    TransitionComponent={Zoom}
    arrow
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#5932EA",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#fff",
    backgroundColor: "#5932EA",
  },
}));

export default BootstrapTooltip;
