import React, { useEffect, useState } from "react";
import { emphasize, styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  Chip,
  Grid,
  Table,
  TableSortLabel,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import { AiOutlineSearch } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { AiOutlineEye } from "react-icons/ai";
import CreateShipment from "./Action/CreateShipment";
import CustomTooltip from "../../component/CustomTooltip/CustomTooltip";
import { axiosInstance } from "../../component/axios/axiosInstance";
import DeleteShipment from "./Action/DeleteShipment";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});
export default function Home() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState(null);
  const handleSearchClose = () => {
    setSearchTerm("");
  };
  const [respShipment, setShipment] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleRequestSort = (event, key) => {
    const isAsc = orderBy === key && order === "asc";
    const newOrder = orderBy === key ? (isAsc ? "desc" : "asc") : "desc";
    setOrder(newOrder);
    setOrderBy(key);
  };
  const SortValue = order === "asc" ? 1 : -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };
  const ViewProfileHandle = () => {
    navigate("/shipment-details");
  };

  const apiUrl = "/api/order/fetchOrders";

  const params = {
    search: searchTerm,
    filter: { status: { $ne: -3 } },
    page: page + 1,
    limit: rowsPerPage,
    select: {},
    startDate: "",
    endDate: "",
    sort: orderBy == "" ? { updatedAt: -1 } : { [orderBy]: SortValue },
  };

  async function fetchData() {
    try {
      const response = await axiosInstance.get(apiUrl, { params });
      setShipment(response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  useEffect(() => {
    if (searchTerm?.length > 0) {
      fetchData();
    } else if (orderBy && order) {
      fetchData();
    } else {
      fetchData();
    }
  }, [searchTerm, page, rowsPerPage, SortValue]);
  return (
    <>
      <Grid container justifyContent="space-between" mt={13}>
        <Typography variant="h6">Manage Shipments</Typography>
        <CreateShipment fetchData={fetchData} />
      </Grid>
      <TextField
        size="small"
        className="search-input mt-20px"
        placeholder="Search  UID or Name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{
          "& .MuiOutlinedInput-root": { borderRadius: 4 },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AiOutlineSearch fontSize="large" />
            </InputAdornment>
          ),
          endAdornment: searchTerm?.length > 0 && (
            <InputAdornment position="end">
              <IoClose
                fontSize="large"
                className="cursor-point"
                onClick={handleSearchClose}
              />
            </InputAdornment>
          ),
        }}
      />
      <Typography variant="subtitle2" pt={2}>
        Showing {respShipment?.orders?.result?.length} of{" "}
        {respShipment?.orders?.totalCount} Shipments
      </Typography>
      <TableContainer className="mt-20px ">
        <Table
          stickyHeader
          aria-label="sticky table"
          className="mt-20"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell className="tablecell-bg">
                <TableSortLabel
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? order : "asc"}
                  onClick={(event) => handleRequestSort(event, "name")}
                >
                  Shipment Name
                </TableSortLabel>
              </TableCell>
              <TableCell className="tablecell-bg">
                <TableSortLabel
                  active={orderBy === "status"}
                  direction={orderBy === "status" ? order : "asc"}
                  onClick={(event) => handleRequestSort(event, "status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell className="tablecell-bg">
                <TableSortLabel
                  active={orderBy === "origin"}
                  direction={orderBy === "origin" ? order : "asc"}
                  onClick={(event) => handleRequestSort(event, "origin")}
                >
                  Origin
                </TableSortLabel>
              </TableCell>

              <TableCell className="tablecell-bg">Current Location</TableCell>
              <TableCell className="tablecell-bg">
                <TableSortLabel
                  active={orderBy === "destination"}
                  direction={orderBy === "destination" ? order : "asc"}
                  onClick={(event) => handleRequestSort(event, "destination")}
                >
                  Destination
                </TableSortLabel>
              </TableCell>
              <TableCell className="tablecell-bg">
                <TableSortLabel
                  active={orderBy === "alerts"}
                  direction={orderBy === "alerts" ? order : "asc"}
                  onClick={(event) => handleRequestSort(event, "alerts")}
                >
                  Alerts
                </TableSortLabel>
              </TableCell>
              <TableCell className="tablecell-bg">
                <TableSortLabel
                  active={orderBy === "updatedAt"}
                  direction={orderBy === "updatedAt" ? order : "asc"}
                  onClick={(event) => handleRequestSort(event, "updatedAt")}
                >
                  Last Updated
                </TableSortLabel>
              </TableCell>

              <TableCell className="tablecell-bg">
                <TableSortLabel
                  active={orderBy === "createdAt"}
                  direction={orderBy === "createdAt" ? order : "asc"}
                  onClick={(event) => handleRequestSort(event, "createdAt")}
                >
                  Created At
                </TableSortLabel>
              </TableCell>

              <TableCell className="tablecell-bg">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {respShipment?.orders?.result?.map((item, index) => {
              return (
                <TableRow key={index} hover className="clickable-row">
                  <TableCell className="tablecrow-cell-bg ">
                    {item?.name} <br />
                    <span className="tablecell-bg"> {item?.order_id}</span>
                  </TableCell>
                  <TableCell className="">
                    {item?.status === 1 ? (
                      <Typography className="status-ongoing">
                        Ongoing
                      </Typography>
                    ) : (
                      <Typography className="status-complete">
                        Complete
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell className="tablecrow-cell-bg ">
                    {item?.origin}
                    <br />
                    <span className="tablecell-bg">
                      {item?.originLocation?.coordinates[0]},
                      {item?.originLocation?.coordinates[1] ?? "--"}
                    </span>
                  </TableCell>
                  <TableCell className="tablecrow-cell-bg ">
                    {item?.currentLocation?.name ?? "--"}
                    <br />
                    <span className="tablecell-bg">
                      {item?.currentLocation?.coordinates[0] ?? ""}{" "}
                      {item?.currentLocation?.coordinates[0] ? "," : ""}
                      {item?.currentLocation?.coordinates[1] ?? ""}
                    </span>
                  </TableCell>

                  <TableCell className="tablecrow-cell-bg ">
                    {item?.destination}
                    <br />
                    <span className="tablecell-bg">
                      {item?.destinationLocation?.coordinates[0]},
                      {item?.destinationLocation?.coordinates[1]}
                    </span>
                  </TableCell>

                  <TableCell
                    className={item?.alerts < 1 ? "tablecrow-cell-bg" : "error"}
                  >
                    {item?.alerts}
                  </TableCell>

                  <TableCell className="tablecrow-cell-bg ">
                    {item?.updatedAt
                      ? dayjs(item?.updatedAt).format("h:mm A")
                      : dayjs(item?.createdAt).format("h:mm A")}

                    <br />
                    <span className="tablecell-bg">
                      {item?.updatedAt
                        ? dayjs(item?.updatedAt).format("DD-MM-YYYY")
                        : dayjs(item?.createdAt).format("DD-MM-YYYY")}
                    </span>
                  </TableCell>

                  <TableCell className="tablecrow-cell-bg">
                    {dayjs(item?.createdAt).format("h:mm A")} <br />
                    <span className="tablecell-bg">
                      {dayjs(item?.createdAt).format("DD-MM-YYYY")}
                    </span>
                  </TableCell>
                  <TableCell className="tablecrow-cell-bg ">
                    <Grid container justifyContent={"space-between"}>
                      <Grid item>
                        <CustomTooltip title={"View Profile"}>
                          <IconButton
                            onClick={() =>
                              navigate(`/shipment-details/${item._id}`)
                            }
                          >
                            <AiOutlineEye />
                          </IconButton>
                        </CustomTooltip>
                      </Grid>

                      <Grid item>
                        {item?.status == 0 ? (
                          ""
                        ) : (
                          <DeleteShipment item={item} fetchData={fetchData} />
                        )}{" "}
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {respShipment?.orders?.result?.length < 1 && (
        <Grid
          container
          justifyContent={"center"}
          alignContent={"center"}
          sx={{ height: "40vh" }}
        >
          <Typography>No Data Found</Typography>
        </Grid>
      )}
      <div style={{ marginBottom: "50px" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={respShipment?.orders?.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
